import { HttpClientModule, HttpClient } from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {RouterModule} from '@angular/router';

// Created Components
import { DataChartsComponent } from './components/datacharts/data-charts.component';
import { PieChartComponent } from './components/piechart/pie-chart.component';
import { BarChartComponent } from './components/barchart/bar-chart.component';

// Service
import { BarChartDataService } from './services/bar-chart-data.service';
import { PieChartDataService } from './services/pie-chart-data.service';
import { ChartTemplateService } from './services/chart-template.service';
import { ConfirmationdialogComponent } from './components/confirmationdialog/confirmationdialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ApplicationHttpClient, applicationHttpClientCreator} from './services/interceptor/http.client';
import { ProgressBarService } from '../shared/services/progress-bar/progress-bar.service';
import { SimpleChartComponent } from './components/simple-chart/simple-chart.component';
import { TypeAheadComponent } from './components/typeahead-core/typeahead';
// import { AuthenticationService } from '@quicksuite/commons-auth-plugin';
import { I18NextModule } from 'angular-i18next';
import { CustomAnchorTagDirective } from '../core/directives/custom-anchor-tag.directive';
import { StudentStoryComponent } from './components/student-stories/student-story/student-story.component';
import { NextCardsComponent } from './components/next-cards/next-cards.component';
import { RegisterComponent } from './components/register/register.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ApkButtonModule, ApkInputModule, ApkNotificationModule, ApkNotificationService, ApkTabsModule } from '@ak4a/components';

import { ButtonModule } from '@appkit4/angular-components/button';
import { FieldModule } from '@appkit4/angular-components/field';
//import { NotificationModule } from '@appkit4/angular-components/notification';
//import { NotificationService } from '@appkit4/angular-components/notification';
import { TabsModule } from '@appkit4/angular-components/tabs';

import { UserProfileService } from './services/user-profile.service';
import { PendingChangesGuard } from './services/can-deactivate.service';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { ApkModalModule } from '@ak4a/components';
import { ModalModule } from 'ngx-bootstrap/modal';

// import { ApkCheckboxModule } from '@ak4a/Components';
import { RegistrationModalComponent } from './components/registration-modal/registration-modal.component';
// import { ApkDropdownModule} from '@ak4a/components';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { BrowserModule } from '@angular/platform-browser';

import { KalturaService } from './services/kaltura.service';
import { MatSelectModule } from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { TermsconditionsComponent } from './components/termsconditions/termsconditions.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { ModalModule as AkModalModule } from '@appkit4/angular-components/modal';
import { DropdownModule } from '@appkit4/angular-components/dropdown';
import { FaqQuesAnsBubbleComponent } from './components/faq-ques-ans-bubble/faq-ques-ans-bubble.component';
import { QuotesCardComponent } from './components/quotes-card/quotes-card.component';



@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    HttpClientModule,
    MatDialogModule,
    I18NextModule,
    ReactiveFormsModule,
    ButtonModule,
    FieldModule,
    RouterModule,
    ApkNotificationModule,
    TabsModule,
    AkModalModule,
    ApkButtonModule,
    ApkModalModule,
    //ApkCheckboxModule,
    ApkInputModule,
    ApkTabsModule,
    //ApkDropdownModule,
    DropdownModule,
    MatSelectModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    ApkNotificationModule,
    ModalModule.forRoot(),
    //BrowserModule,
    //BrowserAnimationsModule,
   
  ],
  declarations: [
    RegisterComponent,
    DataChartsComponent,
    PieChartComponent,
    BarChartComponent,
    ConfirmationdialogComponent,
    ConfirmationModalComponent,
    SimpleChartComponent,
    TypeAheadComponent,
    CustomAnchorTagDirective,
    StudentStoryComponent,
    NextCardsComponent,
    UserProfileComponent,
    RegistrationModalComponent,
    TermsconditionsComponent,
    ErrorModalComponent,
    FaqQuesAnsBubbleComponent,
    QuotesCardComponent
  ],
  exports: [
    RegisterComponent,
    DataChartsComponent,
    PieChartComponent,
    BarChartComponent,
    ConfirmationdialogComponent,
    TypeAheadComponent,
    NextCardsComponent,
    RegistrationModalComponent,
    FaqQuesAnsBubbleComponent,
    QuotesCardComponent,
    ButtonModule,
    AkModalModule,
    TabsModule,
    ApkNotificationModule,
    DropdownModule,
    ApkButtonModule,
    ApkModalModule,
    // ApkCheckboxModule,
    ApkTabsModule,
    // ApkDropdownModule,
    ApkNotificationModule,
    TermsconditionsComponent,
    ErrorModalComponent,
  ],
  providers: [ChartTemplateService, PieChartDataService, BarChartDataService,UserProfileService,PendingChangesGuard, KalturaService, ApkNotificationService,
    {
      provide: ApplicationHttpClient,
      useFactory: applicationHttpClientCreator,
      deps: [HttpClient,
        ProgressBarService]
    }],

     schemas:[CUSTOM_ELEMENTS_SCHEMA]

})
export class SharedModule { }
