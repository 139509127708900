<!-- <apk-modal [(display)]="modalDisplay">
    <apk-modal-header>
      Basic Modal Title
    </apk-modal-header>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione rem fugiat modi doloremque magni eligendi
    alias architecto in aut voluptate nihil omnis nostrum dolorum, adipisci sapiente incidunt? Repudiandae,
    nulla non!
    <apk-modal-action>
      <apk-button type="secondary" (clicked)="closeModal()">
        Cancel
      </apk-button>
      <apk-button type="primary" (clicked)="closeModal()">
        Confirm
      </apk-button>
    </apk-modal-action>
  </apk-modal>
  <apk-button type="primary" (clicked)="openModal()">
    Open
  </apk-button> -->
<div class="Modelstyle">
  <div class="modal-header modal-block-primary Modelstyle">
    <button type="button" class="close" (click)="action(false)">
      
    </button>
    <h4 class="modal-title text-center">Save Changes?</h4>
  </div>
  <div class="modal-body clearfix">
    <div class="modal-text">
      <p>Click yes to review fields and hit save</p>
    </div>
  </div>
  <div class="modal-footer">
    <apk-button  type="secondary"  (click)="action(true)">No</apk-button>
    <apk-button type="primary"  (click)="action(false)">Yes</apk-button>
  </div>
</div>
