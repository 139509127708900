import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-quotes-card',
  templateUrl: './quotes-card.component.html',
  styleUrls: ['./quotes-card.component.scss']
})

export class QuotesCardComponent {
  @Input() quotesData: any;
  constructor(public router:Router) {}
  actionRegister() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['/register']));
  }
}
