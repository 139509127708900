// System Components
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from '@quicksuite/commons-auth-plugin';

// Custom Components

const routes: Routes = [
    {
        path: '',
        // Pass guards into canActivateChild which will act as the main auth.
        // canActivateChild: [AuthGuard],
        children: [
            { path: '', loadChildren: () => import('./main/main.module').then(m => m.MainModule) }
        ]
    },
    
    {
        path: 'error',
        children: [
            { path: '', loadChildren: () => import('./errors/errors.module').then(m => m.ErrorsModule) }
        ]
    },
    { path: '**', redirectTo: '' }
];

const config: ExtraOptions = {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    //relativeLinkResolution: 'legacy'
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
