import { environment } from './../../../environments/environment';
import { Component, OnInit, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { Router } from '@angular/router';
import { MediaService } from '../../core/services/media.service'
declare interface MenuRoutes {
  path: string;
  title: string;
  subItems: Array<any>;
  canShow : Boolean;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit{
  showReg:boolean=false;
  hasError: boolean = false;
  emailUrl: string;
  customResend: boolean = false;
  resend: any ={};
  errorType: ErrorModalType;
  
  constructor(@Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService, private router: Router, private mediaService : MediaService) { }
  menuRoutes: MenuRoutes[] = [
    
    {
      path: '/home', title: this.i18NextService.t('sidebar.home'), subItems: [], canShow : true
    },
    {
      path: '/skills', title: this.i18NextService.t('sidebar.learningexamples'), subItems: [], canShow : true
    },
    {
      path: '/mentors', title: this.i18NextService.t('sidebar.mentoring'), subItems: [], canShow : true
    },
    {
      path: '/careers', title: this.i18NextService.t('sidebar.career'), subItems: [], canShow : true
    },
    // {
    //   path: '/tiktok', title: this.i18NextService.t('sidebar.events'), subItems: [], canShow : true
    // },
    {
      path: '/stories', title: this.i18NextService.t('sidebar.stories'), subItems: [
   
      ], 
      canShow: true
    },
    {
      path: '/faq', title: this.i18NextService.t('sidebar.faq'), subItems: [], canShow : true
    },
    {
      path: '/about', title: this.i18NextService.t('sidebar.about'), subItems: [], canShow :  true
    },
    {
      path: '/Terms&conditions', title: this.i18NextService.t('Terms & Conditions'), subItems: [], canShow :  true
    },
    {
      path: '/register', title: this.i18NextService.t('sidebar.register'), subItems: [], canShow :  true
    }
  ];

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  actionMenuItem(item ) {
    this.redirectTo(item)
    this.closeNav();
  }
  openError(errorType: ErrorModalType) {
    this.emailUrl = '';
    this.hasError = true;
    this.errorType = errorType
    this.customResend = true;
  }
  closeError() {
    this.hasError = false;
    this.showReg=false;
    this.resend={};
  }
  regModalClose(val) {
    if (val == 'isclose') {
      this.showReg = true;
    } else if(val=='close'){
      this.showReg = false;
    }
  }
  resendConfirmation(event) {
    let resend={};
    resend['isresend'] = true;
    resend['type']=event;
    this.resend=Object.assign(resend);
    this.hasError = false;
    }
  redirectTo(uri:string){

    if (uri === '/about') {
      window.open(environment.aboutUsTxt, "_blank");
    } else {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate([uri]));
    }
 }
 
  ngOnInit(){
   let studentRoutes = []
   this.mediaService.getStudentContent('all').subscribe( (response: any) => {
    response.data.forEach(element => {
      if(element.active && element.storyDisplayInApplication) {
        studentRoutes.push({ title: element.menuTitle, path: element.routePath})}
    });

    // this is kind of a terrible approach. The location within the array
    // is determined by a magic number. Hopefully we remember to update if
    // new links are added. (already ran into this)
    // this.menuRoutes[4].subItems.push(...studentRoutes )
    /**There you go, fixed it! Thanks for pointing out :)*/
    this.menuRoutes.forEach((ele: any) =>{
      if (ele.path === '/stories') {
        ele.subItems.push(...studentRoutes )
      }
    })
   })

  

  }
  

}
export const enum ErrorModalType {
  GENERAL,
  REGISTERED,
  CONFIRMED,
  WELCOMEEMAIL,
  VALIDATEEMAIL
}
