import { ApplicationHttpClient } from './../../../shared/services/interceptor/http.client';
import { Injectable } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private http: ApplicationHttpClient) { }

  public getQuestions(): Observable<any> {
     return this.http.get('faq').pipe(
       catchError(e => {
         return throwError(e);
       }));
   }
}
