<!-- <apk-notification  extraClasses="example-class"></apk-notification> -->
<div class="container-fluid container-bg" id="main">
    <div class="user-profile-header">
        <!-- <div class='col-12 padding0'>
        </div> -->
        <div class='row'>
            <div class='col-4 user-header-title-bg'>
                <h1 class="user-header-title">
                    {{title}}
                </h1>
            </div>
            <div class='col-8 header-img-border'>
                <img [src]="imgPath" alt="{{altTitle}}" class="user-image">
            </div>
        </div>
    </div>
    <div class="row user-profile-content" role="main">
        <div class='col-md-3 col-lg-3'>
        </div>
        <div class='col-sm-8 col-md-6 col-lg-6 profileForm'>
            <form [formGroup]="profileForm">
                <div class='row'>
                    <div class='col-12'>
                        <label class='paddingT3'>
                            First Name <sup>&#9733;</sup>
                        </label>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-12'>
                        <input minlength="1" maxlength="64"
                            [class.is-invalid]='profileForm.get("firstName").invalid && profileForm.get("firstName").touched '
                            class='fullwidth' type="text" formControlName="firstName" placeholder="{{currentProfile.firstName}}">
                        <div
                            *ngIf="(profileForm.get('firstName').invalid && profileForm.get('firstName').touched) ||  profileForm.get('firstName').dirty">
                            <small class='text-danger' *ngIf='profileForm.get("firstName").errors?.required'>First name
                                is required </small>
                        </div>
                    </div>
                </div>

                <div class='row'>
                    <div class='col-12'>
                        <label class='paddingT3'>
                            Last Name<sup>&#9733;</sup>
                        </label>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-12'>
                        <input minlength="1" maxlength="64"
                            [class.is-invalid]='profileForm.get("lastName").invalid && profileForm.get("lastName").touched '
                            class='fullwidth' type="text" formControlName="lastName" placeholder="{{currentProfile.lastName}}">
                        <div
                            *ngIf="(profileForm.get('lastName').invalid && profileForm.get('lastName').touched) ||  profileForm.get('lastName').dirty">
                            <small class='text-danger' *ngIf='profileForm.get("lastName").errors?.required'>Last name is
                                required </small>
                        </div>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-12'>
                        <label class='paddingT3'>
                            User Name <sup>&#9733;</sup>
                        </label>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-12'>
                        <input disabled
                            [class.is-invalid]='profileForm.get("aypAccountID").invalid && profileForm.get("aypAccountID").touched '
                            class='fullwidth' type="text" formControlName="aypAccountID" placeholder="{{currentProfile.aypAccountID}}">
                        <div
                            *ngIf="(profileForm.get('aypAccountID').invalid && profileForm.get('aypAccountID').touched) ||  profileForm.get('aypAccountID').dirty">
                            <small class='text-danger' *ngIf='profileForm.get("aypAccountID").errors?.required'>User
                                name is required </small>
                        </div>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-12'>
                        <label class='paddingT3'>
                            Contact Email Address <sup>&#9733;</sup>
                        </label>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-12'>
                        <input
                            [class.is-invalid]='profileForm.get("email").invalid && profileForm.get("email").touched '
                            class='fullwidth' type="text" formControlName="email" placeholder="{{currentProfile.email}}">
                        <div class="invalid-feedback"
                        *ngIf=" profileForm.controls.email.errors || (!profileForm.controls.email.pristine && profileForm.controls.email.invalid)">
                        <div *ngIf="profileForm.controls.email.errors.required">Contact
                                Email Address is required</div>
                        <div *ngIf="profileForm.controls.email.errors.pattern">Enter valid Contact
                                Email Address</div>

                    </div>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-6 colCancelAlignment'>
                        <apk-button class='BtnAlignment apk-button' type="secondary"   [disabled]="!profileForm.dirty"
                            (click)='Cancel(30000)'>Cancel</apk-button>
                    </div>
                    <div class='col-6 colAlignment'>
                        <apk-button class='BtnAlignment apk-button' type="primary"  [disabled]="!profileForm.dirty"
                            (click)='saveProfile("")'>Save</apk-button>
                    </div>
                </div>

            </form>

        </div>
        <div class='col-md-3 col-lg-3'>

        </div>
    </div>
    <div class='bottomcontainer'>

    </div>
    
</div>
