import { Component, OnInit } from '@angular/core';
import {MatGridListModule} from '@angular/material/grid-list';
import { ApkFooterModule } from '@ak4a/components';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear:number;

  constructor(private router:Router) { 
    this.currentYear= new Date().getFullYear();
  }

  ngOnInit(): void {

  }

  actionAboutAYP() {
    return environment.aboutUsTxt;
  }

  actionTerms() {
    window.open('/Terms&conditions', "_blank");
  }

  structure() {
    window.open("https://www.pwc.com/structure", "_blank");
  }

  navigateToHome() {
    this.router.navigate(['/']);
  }
}
