import { BarChartDataService } from './../../services/bar-chart-data.service';
import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { v4 as uuid } from 'uuid';

declare var $: any;
declare var Highcharts: any;

@Component({
  selector: 'app-simple-chart',
  templateUrl: './simple-chart.component.html',
  styleUrls: ['./simple-chart.component.scss']
})
export class SimpleChartComponent implements OnInit, AfterViewInit {

  @Input() chartData;
  public elementId;
  constructor() { }

  ngOnInit() {
    this.elementId = `chart-${uuid()}`;
  }

  ngAfterViewInit() {
    this.renderChart();
  }



  renderChart() {
    Highcharts.setOptions({
      colors: ['#EE5502', '#A32020', '#ae6800', '#933401', '#6A1CE2', '#0076E5', '#66BB6A', '#F5924D', '#9280B1', '#9805CB', '#B3DCF9', '#855F00', '#E27588', '#2C8646', '#DEB8FF']
    });

    $(`#${this.elementId}`).highcharts(
      this.chartData);
  }

}
