import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ProgressBarService {
    public spinnerStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


    showSpinner(isVisible: boolean) {
        this.spinnerStatus.next(isVisible);
    }
}
