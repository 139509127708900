import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';



@Component({
  selector: 'app-termsconditions',
  templateUrl: './termsconditions.component.html',
  styleUrls: ['./termsconditions.component.scss', '../user-profile/user-profile.component.scss']
})
export class TermsconditionsComponent implements OnInit {
  imgPath = "assets/images/user-profile/Dot-patterns.svg";
  altTitle = 'Terms and Conditions';
  TermsAndconditions: any;
  constructor(private http: HttpClient,
    private titleService: Title) {
    this.titleService.setTitle('Terms and conditions')
    this.getJson();

  }
  getJson() {
    this.http.get('../../../assets/json/terms.json').subscribe(data => {
      this.TermsAndconditions=data['TermsAndconditions'];
    })
  }

  ngOnInit(): void {
  }

}


