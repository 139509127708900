import { ChartTemplateService } from './chart-template.service';
import { Injectable } from '@angular/core';
import pierchartData from './../data/piechartdata.json';

@Injectable()
export class PieChartDataService {
  private piechart;

  constructor(templateService: ChartTemplateService) {
   this.piechart = templateService.PiechartTemplate;
}

 getPiechartData () {
    this.piechart.series = [ pierchartData ] ;
    return this.piechart;
 }



}
