import { PieChartDataService } from './../../services/pie-chart-data.service';
import { Component, OnInit, ViewChild, ElementRef, AfterContentInit, OnDestroy, AfterViewInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-piechart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements AfterViewInit, AfterContentInit  {

  private chartData;
  constructor(private service: PieChartDataService) {

  }
  ngAfterContentInit() {
    this.chartData = this.service.getPiechartData();
  }

  ngAfterViewInit() {
    this.renderChart();
  }

    renderChart() {
     $('#chart-container2').highcharts(
      this.chartData);
  }
}
