<div id="mySidenav" role="navigation" class="sidenav">
    <div class="button-close" (click)="closeNav()" tabindex="0">&times;</div>
    <ul>
        <li *ngFor="let menuItem of menuRoutes" routerLinkActive="active">
            <ng-container *ngIf = menuItem.canShow>
                <a>
                    <span (click)="actionMenuItem(menuItem.path)"  [ngClass]="{ 'open-submit' : menuItem.path === '/register' }" tabindex="0">{{menuItem.title}}</span>
                </a>
                <div style="padding-left: 12%;">
                    <div *ngFor="let subItem of menuItem.subItems" routerLinkActive="active">
                        <a>
                            <span (click)="actionMenuItem(menuItem.path+'/'+subItem.path)"  (keyup.enter)="actionMenuItem(menuItem.path+'/'+subItem.path)" tabindex="0">{{subItem.title}}</span>
                        </a>
                    </div>
                </div>
            </ng-container>
        </li>
    </ul>
</div>

<div class='reg RegistrationFormcontainer' *ngIf='showReg'>
    <apk-modal [(display)]="showReg">
        <app-registration-modal [resend]='resend' (error)="openError($event)"
            (modalClose)='regModalClose($event)' [type]="'nonreg'"></app-registration-modal>
            <!-- <app-register></app-register> -->
    </apk-modal>
</div>
<ng-container *ngIf='hasError'>
    <app-error-modal [customResend]='customResend' [resendUrl]="emailUrl" [errorType]="errorType"
        (resendConfirmation)='resendConfirmation($event)' (close)='closeError()'></app-error-modal>
</ng-container>