
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  constructor(private http:HttpClient) {
    
  }

  addUserDetails(data: any): Observable<any> {
    return this.http.post(`${environment.orchestratorURL}/register`, data);
  }

  getColandStatesDetails(): Observable<any> {
    return this.http.get(`${environment.orchestratorURL}/colleges.json`);
  }

  getReferralDetails(): Observable<any> {
    return this.http.get(`${environment.orchestratorURL}/referrals.json`);
  }

  getTypeDetails(): Observable<any> {
    return this.http.get(`${environment.orchestratorURL}/types.json`);
  }

  public verifyEmailForProfile(body): Observable<any> {
    return this.http.put(`${environment.orchestratorURL}/register/verify/email`, body).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  // public verifyEmailForProfile(body: any): Observable<any> {
  //   return this.http.put('registeremail', body).pipe(
  //     catchError(e => {
  //       return throwError(e);
  //     }));
  // }

}

