import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-confirmationdialog',
  templateUrl: './confirmationdialog.component.html',
  styleUrls: ['./confirmationdialog.component.scss']
})
export class ConfirmationdialogComponent implements OnInit {
  blnUpdProject = false;
  blnCancelUpd = false;
  actionType: any;
  @Output() confirmAction = new EventEmitter();
  updateProject() {
    this.blnUpdProject = true;
  }
  cancelUpdate() {
    this.blnCancelUpd = true;
  }
  ngOnInit() {
  }

  confirm(confirm) {
    this.confirmAction.emit(confirm);
  }
}
