import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, subscribeOn } from 'rxjs/operators';
import { MediaService } from '../../../core/services/media.service';
import { ApkCardsModule } from "@ak4a/components"
import { ErrorModalType } from '../error-modal/error-modal.component';
@Component({
  selector: 'app-next-cards',
  templateUrl: './next-cards.component.html',
  styleUrls: ['./next-cards.component.scss']
})
export class NextCardsComponent implements OnInit {
  @Input() nextCardsInfo : {pageType: string, nextStudentStory} ;
  @Input() ks: string;
  hasError: boolean = false;
  customResend: boolean = false;
  resend:any={};
  showReg: boolean = false;
  cardsData: any;
  signUpModalData: {cardTitle: string,cardImgEntityID: string} = {cardTitle: 'Signup',cardImgEntityID: '1_mtl62mwx'}

  subscription: Subscription;
  errorType: any;
  constructor(private router: Router, public mediaService: MediaService) {}

  ngOnInit(): void {
    this.mediaService.getKalturaSession().subscribe((ksResponse: any) =>{
      this.ks = ksResponse.data.ks;
      let cards = [];
      //1. Get data for next student page
      cards.push(this.mediaService.getStudentContent(this.nextCardsInfo.nextStudentStory))
  
      if (this.nextCardsInfo?.pageType === 'student' || this.nextCardsInfo?.pageType === 'career') {
  
        //2. Get data for mentoring page
        cards.push(this.mediaService.getPageContent("mentoring"))
  
        //3. Get data for learning page
        cards.push(this.mediaService.getPageContent("learning"))
  
      }else {
  
        if (this.nextCardsInfo?.pageType === "learning") {
         
          //2. Get data for mentoring page
          cards.push(this.mediaService.getPageContent("mentoring"))
  
          //3. Get data for Career Exploration
          cards.push(this.mediaService.getPageContent("career"))
        
        } else if (this.nextCardsInfo?.pageType === "mentoring") {
        
          //2. Get data for Career Exploration
          cards.push(this.mediaService.getPageContent("career"))
        
          //3. Get data for  learning page
          cards.push(this.mediaService.getPageContent("learning"))
        }
      }
  
      if (cards.length > 0) {
        this.subscription = combineLatest(cards).pipe(
          map(([studentResponse, secondCardResponse, thirdCardResponse]: Array < any > ) => {
            this.cardsData = [studentResponse.data, secondCardResponse.data, thirdCardResponse.data]
            this.cardsData[0].routePath = 'stories/' + this.cardsData[0].routePath
            this.cardsData[0].title = this.cardsData[0].title + "'s story"
          }),
        ).subscribe()
  
      }
    })
 


  }

  redirect(link: string) {
    this.router.navigateByUrl('/', {
      skipLocationChange: true
    }).then(() =>
      this.router.navigate([link]));
  }

  regModalClose(val) {
    if (val == 'isclose') {
      this.showReg = true;
    } else if(val=='close'){
      this.showReg = false;
    }
  }

  actionRegister() {
    this.showReg = true;
  }

  /**
   * Called when an error occurs. This can happen because of a
   * failure within this component or a failure within a child component
   * 
   * @param errorType the type of error being thrown
   */
  openError(errorType: ErrorModalType) {
    this.hasError = true;
    this.errorType = errorType
    this.customResend = true;
  }

  /**
   * Closes any error modal that is open based on the error boolean flag
   */
   closeError() {
    this.hasError = false;
    this.showReg=false;
    this.resend={};
  }
  resendConfirmation(event) {
    let resend={};
    resend['isresend'] = true;
    resend['type']=event;
    this.resend=Object.assign(resend);
    this.hasError = false;
    }
  
}
