
import { Component, OnInit, Output, EventEmitter, Input, OnChanges, ElementRef, ViewChildren, QueryList, AfterViewInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@quicksuite/commons-auth-plugin';
import { RegistrationService } from '../../services/registration.service';
import { ErrorModalType } from '../error-modal/error-modal.component';
import { environment } from './../../../../environments/environment';


import { Overlay, BlockScrollStrategy } from '@angular/cdk/overlay';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

export interface State {
  name: string;
  id: number;
}

export interface Source {
  sourceName: string;
  sourceId: number;
}

@Component({
  selector: 'app-registration-modal',
  templateUrl: './registration-modal.component.html',
  styleUrls: ['./registration-modal.component.scss'],
  providers: [
    { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] }
  ]
})
export class RegistrationModalComponent implements OnInit, OnChanges {
  @Input() type;
  @Input() resend;
  @Input() reset;
  addUserResponse: any;

  @Output() modalClose = new EventEmitter();
  @Output() error = new EventEmitter();

  PAT_EMAIL = "^[a-zA-Z0-9._%+-]+[a-zA-Z0-9]+@[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]+[.][a-zA-Z]{2,4}$";

  registrationForm: FormGroup;
  Colleges: Array<any>;
  collegeInput: string;
  selectedCollege: any;
  groupOneValue = "";
  selectedType: number = 1;
  stateTitle: string = '';
  selectedState: String = "Choose State";
  selectedReferral: String;
  selectedReferralName: String;
  filteredColleges: any;
  beforeSearchCollages: any;
  showReferralName: boolean = false;
  showState: boolean = true;
  showCollege: boolean = true;
  showOrganization: boolean = false;
  showArea: boolean = false;
  step1: boolean = true;
  step2: boolean = false;
  step3: boolean = false;
  step4: boolean = false;
  checkbox: boolean = false;
  identitySeleted: boolean = false;
  college: any;
  cities: Array<any> = [

  ];
  finalStatesAndColleges = [];
  states: State[] = [];
  referrals: Source[] = [];
  types: Source[] = [];
  seasons: string[] = ['Student', 'Faculty', 'PwcMentor'];
  indentities = [
    { label: 'Black', value: 'BLACK', selected: false },
    { label: 'Latino/Hispanic', value: 'LATINX', selected: false },
    { label: 'Black and at least one other race/ethnicity', value: 'BLACK AND OTHER', selected: false },
    { label: 'Latino/Hispanic and at least one other race/ethnicity', value: 'LATINX AND OTHER', selected: false },
    { label: 'I identify as another race/ethnicity', value: 'OTHER', selected: false },
    { label: 'I choose not to identify', value: 'NOTTOIDENTIFY', selected: false }
  ]
  privacyLink: string;

  constructor(
    public authService: AuthenticationService,
    private fb: FormBuilder,
    private regService: RegistrationService,
  ) {
    this.registrationForm = this.fb.group({
      education: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.PAT_EMAIL)]],
      state: ['', Validators.required],
      college: ['', Validators.required],
      organization: ['', Validators.required],
      area: ['', Validators.required],
      referral: ['', Validators.required],
      referralName: [''],
      terms: [false, Validators.requiredTrue],
      privacy: [false, Validators.requiredTrue]
    });
    this.indentities.forEach(obj => {
      obj.selected = false;
    });
    this.privacyLink = 'https://s40acsr01doc01blkbsa.blob.core.windows.net/csrconnect-affiliate-storage/113/unclassified/7614486.784205.0.pdf?sv=2018-03-28&sr=b&si=csrconnect-affiliate-storage-policy&sig=Mp73HacaOYJwvOwk025mo%2BQ47rt9%2fmoJGlQg5VD%2BQuM%3D&se=9999-12-31T23%3A59%3A59Z'
  }

  ngOnInit(): void {
    this.regService.getColandStatesDetails().subscribe(res => {
      this.buildDropDownStructure(res.resStates);
    },
      err => {
        this.error.emit(ErrorModalType.GENERAL);
      });

    this.regService.getReferralDetails().subscribe(res => {
      this.referrals = res.sources;
    });

    this.regService.getTypeDetails().subscribe(res => {
      this.types = res.sources;
      this.selectedType = this.types[0].sourceId;
      this.registrationForm.get('education').setValue(this.selectedType);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resend) {
      if (this.resend) {
        if (this.resend.isresend) {
          const data = {
            "firstName": this.registrationForm.value.firstName,
            "lastName": this.registrationForm.value.lastName,
            "email": this.registrationForm.value.email
          }
          this.regService.verifyEmailForProfile(data).subscribe((res) => {
            if (this.addUserResponse.data.user.emailVerified) {
              this.error.emit(ErrorModalType.WELCOMEEMAIL);
            } else if (!this.addUserResponse.data.user.emailVerified) {
              this.error.emit(ErrorModalType.VALIDATEEMAIL);
            }
          }, (error) => {
            if (this.type == 'reg') {
              this.step1 = true;
              this.step2 = false;
              this.step3 = false;
              this.step4 = false;
              this.registrationForm.reset();
              this.selectedState = "Choose State";
              this.selectedReferral = "Choose Referral";
              this.college = '';
            }

            this.error.emit(ErrorModalType.GENERAL);
          });
        }
      }

      if (this.reset) {
        this.close();
        this.reset = true;
      }
    }
  }

  /**
   * Logic for Ethnicity selection values
   * Ethnicity selected can be :
   * EITHER any multiple combination of all values except 'I choose not to identify' 
   * OR just 'I choose not to identify'
  */
  changeIdentity(event) {
    const checked = event.checked;    
    if (event.source.name == 'Black') {
      this.indentities[0].selected = checked;
      this.indentities[5].selected = false;
    } else if (event.source.name == 'Latino/Hispanic') {
      this.indentities[1].selected = checked;
      this.indentities[5].selected = false;
    } else if (event.source.name == 'Black and at least one other race/ethnicity') {
      this.indentities[2].selected = checked;
      this.indentities[5].selected = false;
    } else if (event.source.name == 'Latino/Hispanic and at least one other race/ethnicity') {
      this.indentities[3].selected = checked;
      this.indentities[5].selected = false;
    } else if (event.source.name == 'I identify as another race/ethnicity') {
      this.indentities[4].selected = checked;
      this.indentities[5].selected = false;
    } else if (event.source.name == 'I choose not to identify') {
      this.indentities[0].selected = false;
      this.indentities[1].selected = false;
      this.indentities[2].selected = false;
      this.indentities[3].selected = false;
      this.indentities[4].selected = false;
      this.indentities[5].selected = checked;
    }
    this.identitySeleted = false;
    this.indentities.forEach(item => {
      if (item.selected) {
        this.identitySeleted = true;
      }
    })
  }

  referralChange(value) {
    if(this.referrals.length > 0){
      this.selectedReferralName = this.referrals.filter(referral => {
        return referral.sourceId == value;
      })[0].sourceName;
    }

    if (value === 16 || value === 17 || value === 26) {
      this.registrationForm.controls['referralName'].setValidators([Validators.required]);
      this.showReferralName = true;
    } else {
      this.registrationForm.controls['referralName'].clearValidators();
      this.showReferralName = false;
    }

    this.registrationForm.controls['referralName'].updateValueAndValidity();
  }

  changeState(val) {
    var colleges = this.finalStatesAndColleges;

    const state = colleges.filter(element => {
      return element.stateId == this.registrationForm.value.state;
    });

    if (state.length > 0) {
      this.filteredColleges = state[0]['College'];
      this.beforeSearchCollages = this.filteredColleges;
    }

    this.registrationForm.controls.college.setValue('');
    this.collegeInput = '';
  }

  changeType(val) {
    if (this.selectedType == 4 || this.selectedType == 5 || this.selectedType == 6) {
      this.registrationForm.controls['college'].clearValidators();
      this.registrationForm.controls['organization'].clearValidators();
      this.registrationForm.controls['area'].clearValidators();

      this.showState = true;
      this.showCollege = false;
      this.showOrganization = false;
      this.showArea = false;

      if (this.selectedType == 6) {
        this.registrationForm.controls['state'].clearValidators();
        this.registrationForm.controls['organization'].setValidators([Validators.required]);
        this.registrationForm.controls['area'].setValidators([Validators.required]);
        this.showState = false;
        this.showOrganization = true;
        this.showArea = true;
      }
    } else {
      this.registrationForm.controls['state'].setValidators([Validators.required]);
      this.registrationForm.controls['college'].setValidators([Validators.required]);
      this.registrationForm.controls['organization'].clearValidators();
      this.registrationForm.controls['area'].clearValidators();
      this.showState = true;
      this.showCollege = true;
      this.showOrganization = false;
      this.showArea = false;
    }
    this.registrationForm.controls['state'].updateValueAndValidity();
    this.registrationForm.controls['college'].updateValueAndValidity();
    this.registrationForm.controls['organization'].updateValueAndValidity();
    this.registrationForm.controls['area'].updateValueAndValidity();
  }

  searchColleges(event: any) {
    if (event?.srcElement?.value && event.key !== 'Enter' && event.key !== 'Tab') {
      const searchValue = event?.srcElement?.value;
      var colleges = this.beforeSearchCollages;

      this.filteredColleges = colleges.filter(item => {
        return item.SchoolName.toLowerCase().includes(searchValue.toLowerCase());
      });

      this.registrationForm.controls['college'].setValue(null);
      this.registrationForm.controls['college'].updateValueAndValidity();
    }
  }

  collegeChange(college) {
    this.selectedCollege = college;
    this.registrationForm.controls['college'].setValue(college.SchoolName);
    this.registrationForm.controls['college'].updateValueAndValidity();
    this.collegeInput = college.SchoolName;
  }

  collegeSelected(event) {
    this.selectedCollege = event.option.value;
    this.registrationForm.controls['college'].setValue(event.option.value);
    this.registrationForm.controls['college'].updateValueAndValidity();
    this.collegeInput = event.option.value;
  }

  onCollegefoucs() {
    if (this.registrationForm.value.state == '') {
      delete this.filteredColleges;
    }
  }

  buildDropDownStructure(resStates) {
    var groupBy = function (resStates, StateProvince) {
      return resStates.reduce(function (rv, x) {
        (rv[x[StateProvince]] = rv[x[StateProvince]] || []).push(x);
        return rv;
      }, {});
    };

    var groubedByTeam = groupBy(resStates, 'StateProvince');

    for (const [key, value] of Object.entries(groubedByTeam)) {
      if (key !== "undefined") {
        var required = {
          name: key,
          stateId: value[0].stateId,
          College: value,
        };

        this.finalStatesAndColleges.push(required);
      }
    }

    this.finalStatesAndColleges.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
  }

  referralDescription(value: any) {
    return true;
  }

  NextStep1() {
    if (this.registrationForm.invalid) {
      return false;
    } else {
      if (this.selectedType == 1 || this.selectedType == 4 || this.selectedType == 5) {
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
      } else {
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.SubmitData();
      }
    }
    this.showUp()
  }

  showUp() {
    const element = document.querySelector('#goUp');
    element.scrollIntoView();
  }  

  identification(val, event) {
    if (event.target.value) {
      setTimeout(() => {
        this.checkbox = false;
      })
    }
  }

  Login() {
    return environment.facultyReg;
  }

  /**
   * @returns false if the form data is not valid else makes a call to register api with student data as payload
   * 
   * If we get 200 response from the api, Next Steps modal will open 
   * Else Error Modal will open
   */
  NextStep3() {
    this.SubmitData();
    this.showUp()
  }

  SubmitData() {
    let ethnicity = [];

    // Extracting the selected ethnicity value(s) and pushing it into an array which will be passed in the register api payload
    if (this.registrationForm.valid) {
      this.indentities.forEach(obj => {
        if (obj.selected) {
          ethnicity.push(obj.value);
        }
      });

      // IF none of the ethinicity values are selected form is not valid and hence should not make a call to register api
      // if (ethnicity.length < 1) { return false; }
      if ( ethnicity.length == 0) {
        ethnicity.push('N/A');
      }
      const data = {
        "education": this.getEducationType(this.registrationForm.value.education),
        "firstName": this.registrationForm.value.firstName,
        "lastName": this.registrationForm.value.lastName,
        "email": this.registrationForm.value.email,
        "state": this.getStateName(this.registrationForm.value.state),
        "college":  this.registrationForm.value.education > 3 ? 'N/A' : this.registrationForm.value.college,
        "tncAccepted": this.registrationForm.value.terms,
        "ethnicity": ethnicity,
        "privacyAccepted": this.registrationForm.value.privacy,
        "findAboutUs": this.selectedReferralName,
        "orgName": this.registrationForm.value.referralName,
        "organization": this.registrationForm.value.organization,
        "area": this.registrationForm.value.area
      }
      this.regService.addUserDetails(data).subscribe(res => {
        if (res.message == 'User already registered') {
          this.addUserResponse = res;
          if (res.data.user.emailVerified) {
            this.error.emit(ErrorModalType.CONFIRMED);
          } else if (!res.data.user.emailVerified) {
            this.error.emit(ErrorModalType.REGISTERED);
          }
        } else {
          this.step1 = false;
          this.step2 = false;
          this.step3 = true;
        }
      },
        err => {
          this.error.emit(ErrorModalType.GENERAL);
        });
    } else {
      return false;
    }
  }

  getStateName(stateId){
    if(stateId == 'Choose State') {
      return 'N/A';
    }
    const stateObj = this.finalStatesAndColleges.find(o => o.stateId === stateId);
    return stateObj.name;
  }

  getEducationType(sourceId){
    const typeObj = this.types.find(o => o.sourceId === sourceId);
    return typeObj.sourceName;
  }

  /**
   * Reverts the flow from step two to step one while maintaining the data that has
   * already been entered by the user.
   */
  backToSetp1() {
    this.step1 = true;
    this.step3 = false;
    this.step2 = false;
    // Forcing a 0 time timeout so the form value of college will not be set until
    // the next processing cycle. This is to avoid the form being set before it is
    // rendered.
    setTimeout(() => { this.collegeChange(this.selectedCollege); }, 0)
    this.registrationForm.controls['college'].setValue(this.collegeInput);
    this.registrationForm.controls['college'].updateValueAndValidity();
    this.showUp();
  }

  openURLInNextTab(url: string) {
    window.open(url, "_blank");
  }

  close() {
    this.registrationForm.reset();

    if (this.type == 'reg') {
      this.step1 = true;
      this.step2 = false;
      this.step3 = false;
      this.step4 = false;
      this.collegeInput = null;
      this.selectedCollege = null;
      this.selectedState = "Choose State";
      this.selectedReferral = "Choose Referral";
      this.registrationForm.reset();
      this.registrationForm.updateValueAndValidity();
      this.indentities[0].selected = false;
      this.indentities[1].selected = false;
      this.indentities[2].selected = false;
      this.indentities[3].selected = false;
      this.indentities[4].selected = false;
      this.indentities[5].selected = false;
      this.identitySeleted = false;
    } else if (this.type == 'nonreg') {
      this.modalClose.emit('close');
    } else {
      this.modalClose.emit('isclose');
    }
  }

  /**
   * Validates that the value of the form control conforms with the standards set forth for a proper name
   * 
   * @param name the form control of the name to validate
   */
  validateName(nameControl: FormControl) {
    var name = nameControl.value;
    if (name) {
      name = name.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');

      if (!(name && name.trim() && isNaN(name.trim()))) {
        nameControl.setValue(null);
        nameControl.updateValueAndValidity();
      } else {
        nameControl.setValue(name);
      }
    }
  }
}
