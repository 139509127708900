import { DOCUMENT } from '@angular/common';
import { Component, OnInit, ElementRef, Inject, Injectable, AfterViewInit, Output, SecurityContext, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';
//Testing
import { MediaService } from '../../../../core/services/media.service';
import { KalturaService } from '../../../services/kaltura.service';

@Component({
  selector: 'app-student-story',
  templateUrl: './student-story.component.html',
  styleUrls: ['./student-story.component.scss']
})

@Injectable()
export class StudentStoryComponent implements OnInit, AfterViewInit {
  @Output() currentStory:any ;
  studentVideo : any =['studentVideo'];
  secondaryStudentVideo : any =['secondaryStudentVideo'];
  mainVideoTranscriptURL: string;
  secondVideoTranscriptURL: string;
  ks: any;
  isMobile: boolean = false;
  screenHeight: number;
  screenWidth: number;
  mobileWidthThreshold: number = 768;


  constructor(
    @Inject(DOCUMENT) private document: Document, 
    private route : ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef, 
    private kalturaService : KalturaService, 
    private mediaService : MediaService,
    private titleService: Title,
    private sanitizer:DomSanitizer ) { 
      this.getScreenSize();
  }
    
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.outerHeight;
    this.screenWidth = window.outerWidth;
    this.checkIfMobile();
  }

  checkIfMobile() {
    this.isMobile = (this.screenWidth < this.mobileWidthThreshold);
  }
  
  ngOnInit(): void {
    this.router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          window.location.reload();
        }
      });
    //Fetching the current user from the router params and getting data from sesion storage
    this.route.params.subscribe( (data) =>{
      this.mediaService.getStudentContent(data.id).subscribe((response: any) => {
        this.currentStory = response.data;
        this.mediaService.getStudentContent(this.currentStory.nextStory).subscribe(resp=>{
          if(resp['data']['storyDisplayInApplication'] === false){
            this.currentStory.nextStory = resp['data']['nextStory']
          }
        })
        this.titleService.setTitle(this.currentStory.title + '\'s story')
        // Do not delete this line!!!
        this.ngAfterViewInit();


        this.mediaService.getVideoTranscript(this.currentStory.headerVideoEntityID).subscribe( (result:any) => {
          this.mainVideoTranscriptURL = result.data;
        })
        this.mediaService.getVideoTranscript(this.currentStory.secondaryVideoEntityID).subscribe( (result:any) => {
          this.secondVideoTranscriptURL = result.data;
        })

      })
    })
  }

  ngAfterViewInit() {
    if(this.currentStory?.headerVideoEntityID){
      this.studentVideo.forEach(targetId => {
      this.kalturaService.getKalturaSession().subscribe((response : any)=> {
        this.ks = response.data.ks
        var s = this.document.createElement('script');
        s.type = 'text/javascript';
         s.src = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(`//cdnapisec.kaltura.com/p/1516131/embedPlaykitJs/uiconf_id/46188641?autoembed=true&targetId=${targetId}&entry_id=${this.currentStory.headerVideoEntityID}&config[playback]={\"autoplay\":false}&ks=${this.ks}`));
         this.elementRef.nativeElement.appendChild(s);
        })
      });
    }

    if(this.currentStory?.secondaryVideoEntityID){
      this.secondaryStudentVideo.forEach(targetId => {
      this.kalturaService.getKalturaSession().subscribe((response : any)=> {
        this.ks = response.data.ks
        var s = this.document.createElement('script');
        s.type = 'text/javascript';
         s.src = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(`//cdnapisec.kaltura.com/p/1516131/embedPlaykitJs/uiconf_id/46188641?autoembed=true&targetId=${targetId}&entry_id=${this.currentStory.secondaryVideoEntityID}&config[playback]={\"autoplay\":false}&ks=${this.ks}`));
        this.elementRef.nativeElement.appendChild(s);
        })
      });
    }
  }
}
