import { BarChartDataService } from './../../services/bar-chart-data.service';
import { Component, AfterContentInit, AfterViewInit  } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-barchart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements AfterContentInit, AfterViewInit {

  private chartData;
  constructor(private service: BarChartDataService) {

  }
  ngAfterContentInit() {
    this.chartData = this.service.getBarchartData();
  }

  ngAfterViewInit() {
    this.renderChart();
  }

    renderChart() {
     $('#chart-container1').highcharts(
      this.chartData);
  }

}
