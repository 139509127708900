
<div class="register">
        <app-registration-modal  [resend]='resend' [reset]="resetRegistration" (error)="openError($event)" [type]="'reg'"></app-registration-modal>
</div>

<div class="faq margin-faq-section">
        <app-faq-ques-ans-bubble [questionsData]="signupQuestionsData"></app-faq-ques-ans-bubble>
</div>

<ng-container *ngIf='hasError'>
        <app-error-modal [customResend]='customResend' [resendUrl]="emailUrl" [errorType]="errorType" (resendConfirmation)='resendConfirmation($event)' (close)='closeError()'></app-error-modal>
</ng-container>