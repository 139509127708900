export class  Environment {
  production: boolean;
  serviceDomain: string;
  serviceEndPoints: {[key: string]: string};
  discoveryUrl: string;
  redirect: string;
  clientId: string;
  useAuth: boolean;
  scope: string;
  authCode: string;
  mentorReg: string;
  facultyReg: string;
  orchestratorURL : string;
  aboutUsTxt: string;
}
