import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-faq-ques-ans-bubble',
  templateUrl: './faq-ques-ans-bubble.component.html',
  styleUrls: ['./faq-ques-ans-bubble.component.scss']
})

export class FaqQuesAnsBubbleComponent {
  @Input() questionsData: any;
}
