import {I18NextModule, I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {APP_INITIALIZER, LOCALE_ID} from '@angular/core';
import moment from 'moment';

const options = {
  // whitelist: ['en', 'es'],  // no need to block
  fallbackLng: 'en',
  ns: ['translation'],
  defaultNS: 'translation',
  debug: true,
  returnEmptyString: false,
  backend: {
    loadPath: '/assets/locales/{{lng}}/{{ns}}.json'
  },
  interpolation: {
    format: function(value, format, lng) {
      if (format === 'uppercase') { return value.toUpperCase(); }
      if (value instanceof Date) {
        moment.locale(lng);
        return moment(value).format(format);
      }
      return value;
    }
  }
};

export function appInit(i18next: ITranslationService) {
  return () => i18next
    .use(Backend)
    .use(LanguageDetector as any)
    .init(options);
}

export function localeIdFactory(i18next: ITranslationService)  {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory
  }];

  export const I18N_IMPORTS = [
    I18NextModule.forRoot(),
  ];
  
  export const I18N_MODULE = [
    I18NextModule
  ];
  

