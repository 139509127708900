import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@appkit4/angular-components/button';
import { RouterModule } from '@angular/router';
import { AuthInterceptorService } from './services/auth-interceptor/auth-interceptor.service';
import { GLOBALS, Global } from './global';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularAuthModule } from '@quicksuite/commons-auth-plugin';
import { environment } from '../../environments/environment';
import { OAUTH_CONFIG } from '@quicksuite/commons-auth-plugin';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AngularAuthModule,
    ButtonModule
  ],
  declarations: [],
  exports: [],
  providers: [
    // INCLUDE THIS IF YOU ARE CREATING AN INJECTION TOKEN (shown below)
    { provide: GLOBALS, multi: false, useClass: Global },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    {
      provide: OAUTH_CONFIG,
      useFactory: () => {
        return {
          discoveryUrl: environment.discoveryUrl,
          redirect: window.location.href,
          clientId: environment.clientId,
          timeToRefresh: 300,
          enableLogging: !environment.production,
          useAuth: environment.useAuth,
          scope: environment.scope,
          authCode: environment.authCode,
          authFlow: true, //toggles use of oidc client shim with authorization flow and open am
          autoRefresh: true //silent renew
        };
      }
    }
  ]
})
export class CoreModule { }
