<div class="a-panel a-p-20">
    <div class="row ">
        <div class="col-12 col-md-6">
            <div class="a-panel">

                <div class="a-panel-header">
                    <span class="panel-title"
                        aria-label="Project Charts">{{ 'charts.entity_charts' | i18nextCap: {'entity': 'entities.project' | i18nextCap } }}</span>
                </div>

                <div class="panel-body">
                    <app-simple-chart *ngIf="barChartContentData && pieChartContentData"
                        [chartData]="pieChartContentData"></app-simple-chart>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="a-panel">

                <div class="panel-body">
                    <app-simple-chart *ngIf="barChartContentData && pieChartContentData"
                        [chartData]="barChartContentData"></app-simple-chart>
                </div>
            </div>
        </div>
    </div>
</div>