import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { NEVER, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { ApplicationUser, AuthenticationService } from '@quicksuite/commons-auth-plugin'



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // user : ApplicationUser;
  showReg:boolean=false;
  hasError: boolean = false;
  emailUrl: string;
  customResend: boolean = false;
  resend: any ={};
  errorType: ErrorModalType;
  isMobile: boolean = false;
  screenHeight: number;
  screenWidth: number;
  mobileWidthThreshold: number = 769;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  resetRegistration: boolean = false;
  headerChangeScreens = ['/skills','/mentors','/careers','/register'];
  // constructor(public authService: AuthenticationService, private route : ActivatedRoute, private router : Router) { }
  constructor(public router:Router) {
    this.getScreenSize();
   }

   @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
        this.checkIfMobile();
  }

  actionOpenMenu() {
    document.getElementById("mySidenav").style.width = "300px";
  }
  navigateToHome(){
    this.router.navigate(['/']);
  }
  
  ngOnInit() {
    //Should copy this to a different component
    

    // this.authService.IsAuthenticatedStream().pipe(
    //   takeUntil(this.ngUnsubscribe),
    //   switchMap(x => {
    //     if (x) {
    //       return this.authService.getUserStream();
    //     }
    //     return NEVER;
    //   }),
    //   takeUntil(this.ngUnsubscribe)
    // )
    // .subscribe(u =>{
    //   this.user = u;
    //   this.router.navigateByUrl('/welcome');
    // });
    this.checkIfMobile();
  }
  checkIfMobile() {
    this.isMobile = (this.screenWidth < this.mobileWidthThreshold);
  }

  // Way to unsubscrive from any observable 
  // https://stackoverflow.com/questions/38008334/angular-rxjs-when-should-i-unsubscribe-from-subscription
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  openError(errorType: ErrorModalType) {
    this.emailUrl = '';
    this.hasError = true;
    this.errorType = errorType
    this.customResend = true;
  }

  closeError() {
    this.hasError = false;
    this.showReg = false;
    this.resend = {};
    this.resetRegistration = true;
  }
  resendConfirmation(event) {
    let resend = {};
    this.resetRegistration = true;
    resend['isresend'] = true;
    resend['type'] = event;
    this.resend = Object.assign(resend);
    this.hasError = false;
    }
  
  regModalClose(val) {
    if (val == 'isclose') {
      this.showReg = true;
    } else if(val=='close'){
      this.showReg = false;
    }
  }

  actionRegister() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate(['/register']));
  }

  /* OpenAM login and logout functionality
  login(): void{
    // this.authService.TryLogin();
    this.authService.NavigateToLogin();
    
  }

  logout(): void{
    this.authService.Logout().then( () => {
      window.location.reload();
    });;
    
  }
  */
}
export const enum ErrorModalType {
  GENERAL,
  REGISTERED,
  CONFIRMED,
  WELCOMEEMAIL,
  VALIDATEEMAIL
}