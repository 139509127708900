<section class="quote-card-section">
    <div class="title1 margin-bottom">From our past participants</div>
    <div class="row quotes-box">
        <div class="col-md-4 quote-col" *ngFor="let quotes of quotesData; let j = index" [attr.data-index]="j">
            <div class="quote-box">
                <div class="quote-text"><div class="quote-text-no-margin" [innerHtml]="quotes.quote"></div>
                <p>- {{quotes.name}}</p>
            </div>
                <!-- <div class="quote-name" ><p> - {{quotes.name}}</p></div> -->
                <div class="bottom-border-black"></div>
                <div class="bottom-border-70"></div>
                <div class="bottom-border-30"></div>
            </div>
        </div>
    </div>
    <div class="sign-up">
        <button  [ngClass]="'sign-up-btn'" class="btn ql-size" (click)="actionRegister()">Get Started</button>
    </div>
</section>