import { ApplicationHttpClient } from './interceptor/http.client';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Injectable()
export class ChartTemplateService {

    constructor(private http: ApplicationHttpClient, 
        @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService) { }

    private piechartTemplate = {
        chart: {
            type: 'pie'
        },
        title: {
            floating: true,
            text: ''
        },
        tooltip: {
            headerFormat: '<span style="color:{point.color}">\u25CF</span>  <b>{point.key}</b><br>',
            backgroundColor:'white',
            pointFormat: '{series.name}: {point.percentage:.0f}%'
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                }
            }
        }
    };

    private barchartTemplate = {
        chart: {
            type: 'column'
        },
        title: {
            text: this.i18NextService.t('navigation.dashboard', "entity: this.i18NextService.t('entities.project')")
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: this.i18NextService.t('navigation.dashboard', "entity: this.i18NextService.t('entities.project')")
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: '{point.y}'
                }
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            backgroundColor:'white',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>'
        }
    };


    get PiechartTemplate() {
        return this.piechartTemplate;
    }

    get BarchartTemplate() {
        return this.barchartTemplate;
    }

    public getBarChartData(): Observable<{}> {
        return this.http.get('barchart')
            .pipe(
                catchError(this.http.handleError('barchart'))
            );

    }

    public getPieChartData(): Observable<{}> {
        return this.http.get('piechart')
            .pipe(
                catchError(this.http.handleError('piechart'))
            );
    }
}
