import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpSentEvent,
  HttpHandler,
  HttpResponse,
  HttpProgressEvent,
  HttpHeaderResponse,
  HttpUserEvent
} from '@angular/common/http';
import { Injector } from '@angular/core';
// import 'app/shared/rxjs-extensions';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@quicksuite/commons-auth-plugin';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  private authService: AuthenticationService;
  constructor(private injector: Injector) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

    if (this.authService && this.authService.IsAuthenticated) {
      const h = {
        'Authorization': this.authService.AuthHeaderValue
      };

      const clone = req.clone({ setHeaders: h });
      return next.handle(clone);
    }

    return next.handle(req);
  }
}
