import { Component, OnInit } from '@angular/core';
import { ErrorModalType } from '../error-modal/error-modal.component';
import { FaqService } from '../../../main/faq/services/faq.service'; 

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  errorType: ErrorModalType;
  hasError: boolean = false;
  customResend: boolean = false;
  resend:any = {};
  resetRegistration: boolean = false;
  showReg: boolean = false;
  // To be populated when call is made to backend to show legitimacy
  emailUrl: string;
  signupQuestionsData:Array<object> = [];
  constructor(private faqService: FaqService,) { }

  ngOnInit(): void {
    this.faqService.getQuestions().subscribe((res) => {
      let questionsData = JSON.parse(JSON.stringify(res.data.content));
      questionsData[4].data.forEach(ele=>{
        if(ele.checkValue){
          ele.ans = ele.ans;
          ele.ques = ele.ques;
          this.signupQuestionsData.push(ele)
        }
      })
    }, (error) => {
    });
  }

  /**
   * Called when an error occurs. This can happen because of a
   * failure within this component or a failure within a child component
   * 
   * @param errorType the type of error being thrown
   */
  openError(errorType: ErrorModalType) {
    this.hasError = true;
    this.errorType = errorType
    this.customResend = true;
  }

  /**
   * Closes any error modal that is open based on the error boolean flag
   */
  closeError() {
    this.hasError = false;
    this.showReg = false;
    this.resend = {};
    this.resetRegistration = true;
  }
  resendConfirmation(event) {
    let resend = {};
    this.resetRegistration = true;
    resend['isresend'] = true;
    resend['type'] = event;
    this.resend = Object.assign(resend);
    this.hasError = false;
    }
}
