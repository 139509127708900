import { Component, OnInit, OnDestroy, Inject, AfterViewInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GLOBALS, Global } from './core/global';
import { AuthenticationService } from '@quicksuite/commons-auth-plugin';
import { ProgressBarService } from './shared/services/progress-bar/progress-bar.service';
import { environment } from '../environments/environment';
import { filter, takeUntil } from "rxjs/operators";
import { KalturaService } from './shared/services/kaltura.service';
import { MediaService } from './core/services/media.service';
import { datadogRum } from '@datadog/browser-rum';
import { NavigationEnd, Router } from '@angular/router';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public navbarisActive = false;
  
  constructor(
    @Inject(GLOBALS) public g: Global,
    public authService: AuthenticationService,
    private spinnerService: ProgressBarService,
    private cdr: ChangeDetectorRef,
    private kalutraService: KalturaService,
    private mediaService: MediaService,
    private router: Router,
  ) {
    const navEndEvents: Observable<Event> = this.router.events.pipe(filter(event => event instanceof NavigationEnd)) as unknown as Observable<Event>;
    navEndEvents.subscribe((event: any) => {
      var config = window['webAppConfig'];
      console.log('config ga', config.ga)
      var ga_id = config.ga.id;
      var ga_token = config.ga.token;
      var ga_env = config.ga.tagEnv;
      gtag('config', ga_id, {
        'page_path': event.urlAfterRedirects,
        'page_title': this.formPageTitle(event.urlAfterRedirects)
      });
    });
  }

  loading = false;
  subMenuItems: any[];

  ngOnInit(): void {
    // this.authService.TryLogin();
    // this.authService.getUserStream().pipe(
    //   takeUntil(this.ngUnsubscribe)
    // ).subscribe(u => this.g.user = u);    

    // if(!sessionStorage.getItem("uiContent")){

    //   this.mediaService.getContent().subscribe((response : any) => {
    //     sessionStorage.setItem("uiContent", JSON.stringify(response.data))  
    //   })
    // }
    this.getDatadogConfig();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit(): void {

  }

  ngAfterViewChecked(): void {
    this.spinnerService.spinnerStatus.subscribe((val: boolean) => {
      if (val !== this.loading) {
        this.loading = val;
        this.cdr.detectChanges();
      }

    });

  }


  formPageTitle(routeString: string) {
    if (routeString.length === 1 && routeString[0] === '/') {
      return 'Home Page';
    } else if (routeString[0] === '/') {
      if (routeString.includes('register')) {
        return 'User Register';
      } else {
        const newStr = routeString.slice(1);
        const formattedStr = newStr[0].toUpperCase() + newStr.slice(1);
        return formattedStr;
      }
    } else {
      return routeString;
    }
  }

  public get showNavigation(): boolean {
    // return true;
    return (!environment.useAuth || this.g.user && this.g.user.unique_name) ? true : false;
  }

  // confuration for datadog monetring for webapp
  private getDatadogConfig() {
    const config = window['webAppConfig'];
    datadogRum.init({
      applicationId: config.dd.applicationId,
      clientToken: config.dd.clientToken,
      site: config.dd.site,
      service: config.dd.webappService,
      env: config.dd.env,
      // Specify a version number to identify the deployed version of your application in Datadog 
      version: config.dd.version,
      trackUserInteractions: false,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel:'mask-user-input'
    });
  }
}
