import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  constructor(private http:HttpClient) {
    
   }
   getUserDetails(id): Observable<any>{
    return this.http.get(`${environment.orchestratorURL}/register/${id}`);
   }

   updateUserDetails(id: string, data: any): Observable<any>{
     return this.http.put(`${environment.orchestratorURL}/register/${id}`, data);
   }
}
