import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Subscription, fromEvent, concat } from 'rxjs';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

import { Key } from './models';
import { ApplicationHttpClient } from './../../services/interceptor/http.client';
import { map, catchError, filter, debounceTime, distinctUntilChanged, switchMap, share } from 'rxjs/operators';

@Component({
  selector: '[typeahead]',
  styles: [`
  .typeahead-backdrop {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }
  `],
  template: `
  <ng-template #suggestionsTplRef>
  </ng-template>
  `
})
export class TypeAheadComponent implements OnInit, OnDestroy {
  @Input() taItemTpl: TemplateRef<any>;
  @Input() taUrl = '';
  @Input() taMethodName = '';
  @Input() taParams = {};
  @Input() taQueryParam = 'q';
  @Output() taSelected = new EventEmitter<string>();
  @Output() taSearched = new EventEmitter<string>();
  @Input('typeAheadSetup') typeAheadSetup: TypeHeadSetup;

  private subject: Subject<string> = new Subject();
  isDataLoading = false;
  results: Array<any>;
  suggestions: string[];

  showSuggestions = false;

  @ViewChild('suggestionsTplRef') suggestionsTplRef;

  private suggestionIndex = 0;
  private subscriptions: Subscription[];
  private activeResult: string;

  constructor(
    private element: ElementRef,
    private viewContainer: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private httpService: ApplicationHttpClient
  ) { }

  @HostListener('keydown', ['$event'])
  handleEsc(event: KeyboardEvent) {
    if (event.keyCode === Key.Escape) {
      this.hideSuggestions();
      event.preventDefault();
    }
  }

  ngOnInit() {
    this.results = [];
    const onkeyDown$ = this.onElementKeyDown();
    this.subscriptions = [
      this.filterEnterEvent(onkeyDown$),
      this.listenAndSuggest(),
      this.navigateWithArrows(onkeyDown$)
    ];
    this.renderTemplate();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions.length = 0;
  }

  renderTemplate() {
    this.viewContainer.createEmbeddedView(this.suggestionsTplRef);
    this.cdr.markForCheck();
  }

  onElementKeyDown() {
    return fromEvent(this.element.nativeElement, 'keydown').pipe(share());
  }

  filterEnterEvent(elementObs: Observable<{}>) {
    return elementObs
      .pipe(filter((e: KeyboardEvent) => e.keyCode === Key.Enter))
      .subscribe((event: Event) => {
        event.preventDefault();
        this.handleSelectSuggestion(this.activeResult);
      });
  }

  listenAndSuggest() {
    return fromEvent(this.element.nativeElement, 'keyup')
      .pipe(
       filter(this.validateKeyCode),
       map((e: any) => e.target.value),
       debounceTime(1000),
      //  concat(),
       distinctUntilChanged(),
       filter((query: string) => query.length >= 2),
       switchMap((query: string) => this.onInputChange(query))
      )
      .subscribe((results: any[]) => {
        'that no match was found';
        this.results = results;
        this.showSuggestions = true;
        this.suggestionIndex = 0;
        this.cdr.markForCheck();
      });
  }

  navigateWithArrows(elementObs: Observable<{}>) {
    return elementObs
      .pipe(
        filter((e: any) => e.keyCode === Key.ArrowDown || e.keyCode === Key.ArrowUp),
        map((e: any) => e.keyCode)
      )
      .subscribe((keyCode: number) => {
        const step = keyCode === Key.ArrowDown ? 1 : -1;
        const topLimit = 9;
        const bottomLimit = 0;
        this.suggestionIndex += step;
        if (this.suggestionIndex === topLimit + 1) {
          this.suggestionIndex = bottomLimit;
        }
        if (this.suggestionIndex === bottomLimit - 1) {
          this.suggestionIndex = topLimit;
        }
        this.showSuggestions = true;
        this.cdr.markForCheck();
      });
  }


  markIsActive(index: number, result: string) {
    const isActive = index === this.suggestionIndex;
    if (isActive) {
      this.activeResult = result;
    }
    return isActive;
  }
  handleSelectSuggestion(suggestion: string) {
    this.hideSuggestions();
    this.taSelected.emit(suggestion);
  }

  validateKeyCode(event: KeyboardEvent) {
    return event.keyCode !== Key.Tab
      && event.keyCode !== Key.Shift
      && event.keyCode !== Key.ArrowLeft
      && event.keyCode !== Key.ArrowUp
      && event.keyCode !== Key.ArrowRight
      && event.keyCode !== Key.ArrowDown
      && event.keyCode !== Key.Backspace;
  }

  hideSuggestions() {
    this.showSuggestions = false;
  }

  hasItemTemplate() {
    return this.taItemTpl !== undefined;
  }
  onInputChange(query: string) {
    const value = query;
    const methodName = this.taMethodName;
    this.showSuggestions = false;

    this.callService(query, methodName).subscribe(res => {


      // Handle data to update UI
      this.typeAheadSetup.asynchDataCall(res, (dataList: Array<any>) => {
        this.results = dataList;
        this.showSuggestions = true;
        this.suggestionIndex = 0;
        this.cdr.markForCheck();
        return this.results.map(
          (result: any) => result[0]);
      });
    }, error => {
     // console.log('On error of searchUserNames: ' + JSON.stringify(error));
    });

    return [];

  }
  callService(query: string, methodname: string): Observable<JSON> {
      return this.httpService.get(methodname , `/${query}`)
      .pipe(
        map(res => res['response']['data']),
        catchError(this.httpService.handleError
          (methodname))
      );

  }
}

export interface TypeHeadSetup {
  customTemplate: string;
  placeHolder: string;
  type: string;
  asynchDataCall: any;
}
