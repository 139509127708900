<div role="main">
    <div class="container" id="main" *ngIf="currentStory">
        <div role="region" class="row student-story-header">
            <div *ngIf="!isMobile" class="color-box padding0px"></div>
            <div id="studentVideo" class="col-7 padding0px"></div>
            <div *ngIf="isMobile" class="color-box padding0px"></div>
            <div class="text-box col-4 padding0px max-width" >
                <div class="row student-story-content">
                    <div class="student-story">
                        <p class="paragraph paragraph-with-link" *ngFor="let paragraph of currentStory.storyContent"
                            [innerHtml]="paragraph">
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row margin-right0px">
            <div class="color-box1 col-7 max-width1">
                <h1 class="student-story-title ">
                    {{currentStory.title}}'s story
                </h1>
            </div>
            <div class="color-box2 col-4 max-width2"></div>
        </div>
        <div class="student-story-color">
            <div class="row secondVideoContainer marginNill">
                <div *ngIf="!isMobile" class="col-2">
                    <div class="color-box3"></div>
                    <div class="color-box4"></div>
                </div>
                
                <div id="secondaryStudentVideo" class="col-8 flex-value"></div>
                <div *ngIf="!isMobile" class="col-2">
                    <div class="color-box5"></div>
                    <div class="color-box6"></div>
                </div>
                <div class="" style="position: relative; display: none;"></div>
                <div class="secondVideoTranscript" *ngIf="secondVideoTranscriptURL !== ''">
                    <a [href]="secondVideoTranscriptURL" aria-label="Download a transcript for video 2">Download
                        transcript</a>
                </div>
                <div *ngIf="isMobile">
                    <div class="color-box7 row"></div>
                    <div class="color-box8 row"></div>
                </div>
            </div>
        </div>
    </div>
</div>