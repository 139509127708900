import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../shared/services/interceptor/http.client';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MediaService {
  private ks;

  constructor(private http: HttpClient) { }

  getContent() {
    return this.http.get(`${environment.orchestratorURL}/content`)
  }

  getPageContent(pageID: string) {
    return this.http.get(`${environment.orchestratorURL}/content/pages/${pageID}`)
  }

  getUserContent(emailID: string) {
    return this.http.get(`${environment.orchestratorURL}/register/emailid/${emailID}`)
  }

  getStudentContent(studentID: string) {
    return this.http.get(`${environment.orchestratorURL}/content/pages/students/${studentID}`)
  }



  getlearningContent(studentID: string) {
    return this.http.get(`${environment.orchestratorURL}/content/pages/learning/${studentID}`)
  }

  getKalturaSession() {
    return this.http.get(`${environment.orchestratorURL}/content/ks`)
  }

  buildImageSrc(entryID, ks) {
    return `https://cfvod.kaltura.com/p/1516131/sp/151613100/thumbnail/entry_id/${entryID}/width/0/height/0/ks/${ks}`
  }
  getBannerMsg() {
    return this.http.get(`${environment.orchestratorURL}/content/bannermessage`)
  }

  getVideoTranscript(entryID) {
    return this.http.get(`${environment.orchestratorURL}/content/transcript/${entryID}`)
  }

  truncateString(text, limit, append) {
    if (typeof text !== 'string')
      return '';
    if (typeof append == 'undefined')
      append = '...';
    var parts = text.split(' ');
    if (parts.length > limit) {
      // loop backward through the string
      for (var i = parts.length - 1; i > -1; --i) {
        // if i is over limit, drop this word from the array
        if (i + 1 > limit) {
          parts.length = i;
        }
      }
      // add the truncate append text
      parts.push(append);
    }
    // join the array back into a string
    return parts.join(' ');
  }
}
