<div id="header" role="banner" class="a-header main" class="header-bckgrnd">
    <div>
        <button  [ngClass]="!(headerChangeScreens.includes(router.url)) ? 'sign-up-btn' : 'sign-up-btn1'" class="btn ql-size" (click)="actionRegister()">Get Started</button>
    </div>
    <span *ngIf="!(headerChangeScreens.includes(router.url))" class="header-hamburger" (click)="actionOpenMenu()" (keyup.enter)="actionOpenMenu()" tabindex="0"></span>
    <span *ngIf="isMobile && (headerChangeScreens.includes(router.url))" class="header-hamburger1" (click)="actionOpenMenu()" (keyup.enter)="actionOpenMenu()" tabindex="0"></span>
    <app-sidebar></app-sidebar>
    <div class="a-hfn-header-container d-flex align-items-center justify-content-between  navigation-fourth-bar">
        <div class="d-flex align-items-center">
            <div class="ayp" [routerLink]="['/home']">
                Access Your Potential
            </div>
                <div *ngIf="headerChangeScreens.includes(router.url) &&  !isMobile" [ngClass]="router.url == '/skills'?'active_route':''" class="ayp skills-position" [routerLink]="['/skills']" >
                    Skills
                </div>
                <div *ngIf="headerChangeScreens.includes(router.url) &&  !isMobile" [ngClass]="router.url == '/mentors'?'active_route':''" class="ayp mentors-position" [routerLink]="['/mentors']">
                    Mentors
                </div>
                <div *ngIf="headerChangeScreens.includes(router.url) &&  !isMobile" [ngClass]="router.url == '/careers'?'active_route':''" class="ayp careers-position" [routerLink]="['/careers']">
                    Careers
                </div>
        </div>
    </div>
    

</div>