<apk-modal [titleLabel]="error.header" class="erroModal" [(display)]="showError" (displayChange)="close.emit()">
    <apk-modal-header>
        {{ error.header }}
    </apk-modal-header>
    <div class='col-12 fontStyles errorPage'>
        <div class='row'>
            <div class='col-12 modal-base'>
                <div class='row paddingTop2'>
                    <div class='col-12 fontStyles paddingT40 errorText'>
                        <div [innerHtml]='error.text1'></div>
                        <br>
                        <div *ngIf='error.text3' [innerHtml]='error.text3'></div>
                        <ng-container *ngIf="error.text2">
                            <br>
                            <div *ngIf='!customResend' [innerHtml]='error.text2'></div>
                            <!-- Not confirmation -->
                            <div *ngIf='customResend && errorType==1'>If you need the confirmation email resent, 
                                <span class='link' (click)='resendUrlLink()'>please click here</span>.</div>
                            <!-- Already confirmation -->
                            <div *ngIf='customResend && errorType !==1'>If you need the welcome email resent, 
                                <span class='link' (click)='resendUrlLink()'>please click here</span>.</div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class='row'>
            <div class='col-6'></div>
            <div class='col-6 marginTop'>
                <apk-modal-action>
                    <apk-button type="primary" (clicked)="close.emit()">
                        Close
                    </apk-button>
                </apk-modal-action>
            </div>
        </div>
    </div>
</apk-modal>