import { InjectionToken, Injectable } from '@angular/core';
import { ApplicationUser } from '@quicksuite/commons-auth-plugin';

@Injectable()
export class Global {
    user: ApplicationUser = {};
    kalturaSession;
}

// Injection token GLOBALS of type Global
export const GLOBALS = new InjectionToken<Global>('GLOBALS');
