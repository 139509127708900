<div class="next-up" *ngIf="cardsData?.length > 0">
    <ol >
         <!-- last card always sign up -->
        <li *ngFor="let card of cardsData">
            <button (click)="redirect(card.routePath)">
                <img [src]="mediaService.buildImageSrc(card.cardImgEntityID, ks)"  class="card-image" [alt]="''"/>
                <div class="card-title">
                    <span>
                        {{card.title}}
                    </span>
                </div>
                <div class="card-footer-design"></div> 
            </button>
        </li>
         <li>
            <button class="open-submit" routerLink=""  (click)="actionRegister()">
                <img [src]="mediaService.buildImageSrc(signUpModalData.cardImgEntityID, ks)"  class="card-image open-submit" alt="Sign Up Image"/>
                <div class="card-title open-submit">
                <span class="open-submit">
                    Sign Up
                </span>
            </div>
            <div class="card-footer-design open-submit"></div> 
            </button>
        </li>       
    </ol>
    <div class='reg RegistrationFormcontainer' *ngIf='showReg'>
        <apk-modal [(display)]="showReg">
            <app-registration-modal  [resend]='resend' (error)="openError($event)" (modalClose)='regModalClose($event)' [type]="'nonreg'"></app-registration-modal>
        </apk-modal>
    </div>
</div>
<ng-container *ngIf='hasError'>
    <app-error-modal [customResend]='customResend' [resendUrl]="emailUrl" (resendConfirmation)='resendConfirmation($event)'
        [errorType]="errorType" (close)='closeError()'></app-error-modal>
</ng-container>
