import {  APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule,Title } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { AngularAuthModule } from '@quicksuite/commons-auth-plugin';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { ApplicationHttpClient } from './shared/services/interceptor/http.client';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { ProgressBarService } from './shared/services/progress-bar/progress-bar.service';
import { ApkHeaderModule, ApkFooterModule, ApkInputModule, ApkButtonModule, ApkCheckboxModule, ApkInputMaskModule, ApkTabsModule, ApkModalModule } from '@ak4a/components';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HeaderModule } from '@appkit4/angular-components/header';
import { FooterModule } from '@appkit4/angular-components/footer';
import { FieldModule } from '@appkit4/angular-components/field';
import { ButtonModule } from '@appkit4/angular-components/button';
import { TabsModule } from '@appkit4/angular-components/tabs';
import { ModalModule as AkModalModule} from '@appkit4/angular-components/modal';
import { DropdownModule } from '@appkit4/angular-components/dropdown';
import {MatGridListModule} from '@angular/material/grid-list';

import { I18NextModule} from 'angular-i18next';
import { I18N_PROVIDERS } from './utils/i18n';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { SharedModule } from './shared/shared.module';
  
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    AppRoutingModule,
    AngularAuthModule,
    ApkTabsModule,
    MatProgressSpinnerModule,
    HeaderModule,
    FooterModule,
    FieldModule,
    ButtonModule,
    TabsModule,
    AkModalModule,
    DropdownModule,
    MatGridListModule,
    I18NextModule.forRoot({
      // errorHandlingStrategy: StrictErrorHandlingStrategy
    }),
    ApkHeaderModule,
    ApkFooterModule,
    FlexLayoutModule,
    ApkInputModule,
    ApkModalModule,
    ApkButtonModule,
    ApkCheckboxModule,
    ApkInputMaskModule,

    SharedModule,
  ],
  exports: [
    //...I18N_MODULE
  ],
  providers: [
    { provide: 'authFlow', useValue: true }, //will default to true in future release
    { provide: 'authRedirectToCurrent', useValue: true },
    ApplicationHttpClient,
    ProgressBarService,
    I18NextModule,
    I18N_PROVIDERS,
    Title  
  ],
  bootstrap: [AppComponent],
  schemas : [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}