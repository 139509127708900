import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserModel } from '../../models/user.model';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from '../../services/can-deactivate.service';
import { UserProfileService } from '../../services/user-profile.service';
import { ApkNotificationService } from '@ak4a/components';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, ComponentCanDeactivate {

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.profileForm.dirty) {
      return false;
    } else {
      return true;
    }


  }
  isConfirmationVisible: boolean = false;
  stories: any = [];
  formData:any;
  currentProfile: UserModel = { firstName: 'please enter first name', lastName: 'please enter last name', aypAccountID: '', userID: '', email: 'please enter email' };
  title = "Profile";
  imgPath = "assets/images/user-profile/AYP 2.0 - Dot patterns - Tangerine_Horizontal abstract.svg";
  altTitle = 'user profile';
  profileForm: FormGroup;
  PAT_EMAIL = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$";

  //have to do this
  //     private isValidEmail(text:string): boolean{
  //         var emailRegEx = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  //         return emailRegEx.test(text);
  //     }

  constructor(
      private fb: FormBuilder,
      private userService: UserProfileService,
      private notificationService: ApkNotificationService) {
    this.profileForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      aypAccountID: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.PAT_EMAIL)]],
    });

  }

  ngOnInit(): void {
    const userId = '123456';
    this.userService.getUserDetails(userId).subscribe((res: any) => {
      this.profileForm.controls['firstName'].setValue(res.data[0].firstName);
      this.profileForm.controls['lastName'].setValue(res.data[0].lastName);
      this.profileForm.controls['aypAccountID'].setValue(res.data[0].aypAccountID);
      this.profileForm.controls['email'].setValue(res.data[0].email);
      this.formData=res.data[0];
      
    });
    setTimeout(() => { }, 100)
  }
  saveProfile(text: string) {
    const userId = '123456';
    let data = this.profileForm.value;
    let updateObj = {};
    (<any>Object).values(this.profileForm.controls).forEach(control => {
      control.markAsTouched();
    });
    if (this.profileForm.valid) {
      if (data.firstName !== '' && data.firstName.length > 1) updateObj['firstName'] = data.firstName
      if (data.lastName !== '' && data.lastName.length > 1) updateObj['lastName'] = data.lastName
      if (data.email !== '' && data.email.length > 1) updateObj['email'] = data.email

      this.userService.updateUserDetails(userId, updateObj).subscribe((res: any) => {
        this.profileForm.controls['firstName'].setValue(res.data.firstName);
        this.profileForm.controls['lastName'].setValue(res.data.lastName);
        this.profileForm.controls['aypAccountID'].setValue(res.data.aypAccountID);
        this.profileForm.controls['email'].setValue(res.data.email);
        this.formData=res.data;
        this.profileForm.markAsPristine();
        this.successMessage(30000);
      }, (error: any) => {
        this.erroMessage(30000);
      })
    } else {
      return false;
    }
  }
  erroMessage(timer) {
    this.notificationService.error('We apologize, something has gone wrong. Please attempt your action at another time', timer, false)
  }
  successMessage(timer) {
    this.notificationService.success('Changes have been successfully updated.', timer, false)
  }
  Cancel(timer) {
    this.profileForm.controls['firstName'].setValue(this.formData.firstName);
    this.profileForm.controls['lastName'].setValue(this.formData.lastName);
    this.profileForm.controls['aypAccountID'].setValue(this.formData.aypAccountID);
    this.profileForm.controls['email'].setValue(this.formData.email);
    this.notificationService.warning('Changes have been canceled.', timer, false)
  }
}
