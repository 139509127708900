<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" data-dismiss="modal">
        <i class="appkiticon icon-outline-close" aria-hidden="true"></i>
      </button>
      <h4 class="modal-title">Action</h4>
    </div>
    <div class="modal-body">
      <p class="desc">Are you sure you want to complete this action?</p>
      <p class="warning-label">Warning: this cannot be undone</p>
    </div>
    <div class="modal-footer">
      <div class="buttons-combined pull-right">
        <button class="button button-secondary" type="button" data-dismiss="modal" (click)="confirm(false)">No</button>
        <button class="button button-primary" type="button" data-dismiss="modal" (click)="confirm(true)">Yes</button>
      </div>
    </div>
  </div>
</div>