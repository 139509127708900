import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private modalService: BsModalService) {}

  canDeactivate(component: ComponentCanDeactivate) {
    // if there are no pending changes, just allow deactivation; else confirm first
    // return component.canDeactivate() ?
    //   true :
    //   // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
    //   // when navigating away from your angular app, the browser will show a generic warning message
    //   // see http://stackoverflow.com/a/42207299/7307355
    //   confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
    if (component['profileForm'].touched) {
      const subject = new Subject<boolean>();
      const modal = this.modalService.show(ConfirmationModalComponent, {'class': 'modal-dialog-primary'});
      modal.content.subject = subject;
      return subject.asObservable();
    } else {
      return true;
    } 
  }
}
