import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BarChartDataService } from './../../services/bar-chart-data.service';
import { ChartTemplateService } from '../../services/chart-template.service';
import { PieChartDataService } from '../../services/pie-chart-data.service';

@Component({
  selector: 'app-datacharts',
  templateUrl: './data-charts.component.html',
  styleUrls: ['./data-charts.component.scss']
})
export class DataChartsComponent implements OnInit, OnDestroy {
  private barchartSubscription: Subscription ;
  private piechartSubscription: Subscription ;

  public barChartContentData: any;
  public pieChartContentData: any;

  constructor(private service: ChartTemplateService, private barChartDataService: BarChartDataService,
  private pieChartDataservice: PieChartDataService) {
  }

  ngOnInit() {  
    this.barChartContentData = this.barChartDataService.getBarchartData();
    this.pieChartContentData = this.pieChartDataservice.getPiechartData();
  }

  ngOnDestroy() {
    
  }
}
