

<div class='registrationForm' id="goUp">
    <div id="title" class="row story-header">
        <div class="title-container">
            <div class="story-title">
                <div *ngIf='step1'>
                    <apk-modal-header>
                        <h1 class='redLabel headerfont'>{{ 'registration.title' | i18next }}</h1>
                    </apk-modal-header>
                </div>
                <div *ngIf='step2'>
                    <apk-modal-header>
                        <h1 class='redLabel headerfont'>{{ 'identification.title' | i18next }}</h1>
                    </apk-modal-header>
                </div> 
                <div *ngIf='step3'>
                    <apk-modal-header>
                        <h1 class='redLabel headerfont'>{{ 'nextSteps.title' | i18next }}</h1>
                    </apk-modal-header>
                </div>
                <!-- <h1>Sign up</h1>  -->
                <section class="description paragraph-with-link" ></section>
            </div>
            <div class="ayp-title">
                <!-- <h2>
                    Access Your Potential
                </h2> -->
                <!-- <button class="sign-up-btn1" (click)="actionRegister()">Sign Up</button> -->
            </div>
        </div>
        <div class="story-image">
            <!-- <img [src]="mediaService.buildImageSrc(currentStory.headerImgEntityID, ks)" [alt]="currentStory.altTxt"> -->
        </div>
    </div>
    <div class="registration-form-container"> 
        <div *ngIf='step1'>
            <div class='row fontparagraphStyles marginT4 paddingBottom'>
                <div class='col-md-9'>
                    {{ 'registration.join' | i18next }}
                </div>
            </div>
        </div>
        <div *ngIf='step2'>
            <div class='row marginT4'>
                <div class='col-md-9 fontStyles'>
                    {{ 'identification.copy1' | i18next }}
                    <br><br>
                    {{ 'identification.copy2' | i18next }}
                </div>
            </div>
        </div> 
        <div *ngIf='step3'>
            <div class='row paddingTop2'>
                <div class='col-md-9 fontStyles'>
                    {{ 'nextSteps.copy1' | i18next }}
                    <br><br>
                    {{ 'nextSteps.copy2' | i18next }}
                </div>
            </div>
        </div>  
        <form [formGroup]="registrationForm" class=" no-padding">
            <div *ngIf='step1' class="col-md-6 no-padding">
                <div class='row fontparagraphStyles paddingBottom'>
                    <div class='col-md-9'>
                        {{ 'registration.welcome' | i18next }}
                    </div>
                </div>
                <div class='row'>
                    <div class='col-md-9 paddingT3'>
                        <label for="education" class="fontStyles">{{ 'registration.type' | i18next }} <sup>&#42;</sup></label>
                    </div>
                </div>
                <div class='row mb-4'>
                    <div class='col-12 '>
                        <mat-select class='select form-box-padding' placeholder="Select a type" formControlName="education"
                            [(ngModel)]="selectedType"
                            (ngModelChange)="changeType($event)"
                            [class.is-invalid]='registrationForm.get("education").invalid && registrationForm.get("education").touched'
                            >
                            <mat-option *ngFor="let type of types" [value]="type.sourceId">
                                <span>{{ type.sourceName }}</span>
                            </mat-option>
                        </mat-select>
                        <div
                            *ngIf="(registrationForm.get('education').invalid && registrationForm.get('education').touched) || registrationForm.get('education').dirty">
                            <small class='text-danger'
                                *ngIf='registrationForm.get("education").errors?.required'>{{ 'registration.collegeStateRequired' | i18next }}
                            </small>
                        </div>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-12'>
                        <label for="fname" class="fontStyles">{{ 'registration.firstName' | i18next }} <sup>&#42;</sup></label>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-12'>
                        <input class='fullwidth form-box-padding'
                                formControlName="firstName"
                                id="firstName"
                                minlength="1"
                                maxlength="64"
                                name="firstName"
                                type="text"
                                aria-label="First Name"
                                [class.is-invalid]='registrationForm.get("firstName").invalid && registrationForm.get("firstName").touched'
                                (focusout)="validateName(registrationForm.get('firstName'))">
                        <div *ngIf="(registrationForm.get('firstName').invalid && registrationForm.get('firstName').touched) ||  registrationForm.get('firstName').dirty">
                            <small class='text-danger' *ngIf='registrationForm.get("firstName").errors?.required'>
                                {{ 'registration.firstNameRequired' | i18next }}
                            </small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class='col-12 paddingT3'>
                        <label for="lname" class="fontStyles">{{ 'registration.lastName' | i18next }} <sup>&#42;</sup></label>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-12'>
                        <input class='fullwidth form-box-padding'
                                formControlName="lastName"
                                id="lastName"
                                maxlength="64"
                                minlength="1"
                                name="lastName"
                                type="text"
                                aria-label="Last Name"
                                [class.is-invalid]='registrationForm.get("lastName").invalid && registrationForm.get("lastName").touched'
                                (focusout)="validateName(registrationForm.get('lastName'))">
                        <div *ngIf="(registrationForm.get('lastName').invalid && registrationForm.get('lastName').touched) ||  registrationForm.get('lastName').dirty">
                            <small class='text-danger' *ngIf='registrationForm.get("lastName").errors?.required'>
                                {{ 'registration.lastNameRequired' | i18next }}
                            </small>
                        </div>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-12 paddingT3'>
                        <label for="email" class="fontStyles">
                            {{ 'registration.email' | i18next }} <sup>&#42;</sup>
                        </label>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-12'>
                        <input class='fullwidth form-box-padding'
                                formControlName="email"
                                type="text"
                                aria-label="Email"
                                [class.is-invalid]='registrationForm.get("email").invalid && registrationForm.get("email").touched'>
                        <div *ngIf=" registrationForm.controls.email.errors || (!registrationForm.controls.email.pristine && registrationForm.controls.email.invalid)" class="invalid-feedback">
                            <div *ngIf="registrationForm.controls.email.errors.required">
                                {{ 'registration.emailRequired' | i18next }}
                            </div>
                            <div *ngIf="registrationForm.controls.email.errors.pattern">
                                {{ 'registration.emailValidRequired' | i18next }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class='row' *ngIf='showState'>
                    <div class='col-12 paddingT3'>
                        <label *ngIf="selectedType == 4 || selectedType == 5" for="university" class="fontStyles"> State: <sup>&#42;</sup></label>
                        <label *ngIf="selectedType != 4 && selectedType != 5" for="university" class="fontStyles">{{ 'registration.collegeState' | i18next }} <sup>&#42;</sup></label>
                    </div>
                </div>
                <div class='row' *ngIf='showState'>
                    <div class='col-12'>
                        <mat-select class='select form-box-padding' placeholder="Select a state" formControlName="state"
                            [(ngModel)]="selectedState"
                            [class.is-invalid]='registrationForm.get("state").invalid && registrationForm.get("state").touched'
                            (ngModelChange)="changeState($event)">
                            <mat-option *ngFor="let state of finalStatesAndColleges" [value]="state.stateId">
                                <span>{{ state.name }}</span>
                            </mat-option>
                        </mat-select>
                        <div
                            *ngIf="(registrationForm.get('state').invalid && registrationForm.get('state').touched) || registrationForm.get('state').dirty">
                            <small class='text-danger'
                                *ngIf='registrationForm.get("state").errors?.required'>{{ 'registration.collegeStateRequired' | i18next }}
                            </small>
                        </div>
                    </div>
                </div>
                <div class='row' *ngIf='showCollege'>
                    <div class='col-12 paddingT3'
                            *ngIf='registrationForm.get("state").value !=null && registrationForm.get("state").value !="Choose State" && registrationForm.get("state").value !=""'>
                        <label class="fontStyles">{{ 'registration.college' | i18next }} <sup>&#42;</sup></label>
                    </div>
                </div>
                <div class='row'
                    *ngIf='showCollege && registrationForm.get("state").value !=null && registrationForm.get("state").value !="Choose State" && registrationForm.get("state").value !=""'>
                    <div class='col-12'>
                        <input matInput class="fullwidth form-box-padding college" placeholder="Start typing a college or university name…"
                            aria-label="College" [matAutocomplete]="auto"
                            [class.is-invalid]='registrationForm.get("college").invalid'
                            (focus)='onCollegefoucs()' (keyup)="searchColleges($event)" [(ngModel)]="collegeInput" [ngModelOptions]="{standalone: true}">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="collegeSelected($event)">
                            <mat-option *ngFor="let college of filteredColleges" [value]="college.SchoolName" (click)="collegeChange(college)">
                                <span>{{ college.SchoolName }}</span>
                            </mat-option>
                        </mat-autocomplete>
                        <div *ngIf="(registrationForm.get('college').invalid && registrationForm.get('college').touched) ||  registrationForm.get('college').dirty">
                            <small class='text-danger' *ngIf='registrationForm.get("college").errors?.required'>
                                {{ 'registration.collegeRequired' | i18next }}
                            </small>
                        </div>
                    </div>
                </div>
                <div class='row paddingT3' *ngIf='showOrganization'>
                    <div class='col-12'>
                        <label for="fname" class="fontStyles">{{ 'registration.organization' | i18next }} <sup>&#42;</sup></label>
                    </div>
                </div>
                <div class='row' *ngIf='showOrganization'>
                    <div class='col-12'>
                        <input class='fullwidth form-box-padding'
                                formControlName="organization"
                                id="organization"
                                minlength="1"
                                maxlength="64"
                                name="organization"
                                type="text"
                                aria-label="Organization Name"
                                [class.is-invalid]='registrationForm.get("organization").invalid && registrationForm.get("organization").touched'
                                (focusout)="validateName(registrationForm.get('organization'))">
                        <div *ngIf="(registrationForm.get('organization').invalid && registrationForm.get('organization').touched) ||  registrationForm.get('organization').dirty">
                            <small class='text-danger' *ngIf='registrationForm.get("organization").errors?.required'>
                                {{ 'registration.organizationRequired' | i18next }}
                            </small>
                        </div>
                    </div>
                </div>
                <div class='row paddingT3' *ngIf='showArea'>
                    <div class='col-12'>
                        <label for="fname" class="fontStyles">{{ 'registration.area' | i18next }} <sup>&#42;</sup></label>
                    </div>
                </div>
                <div class='row'*ngIf='showArea'>
                    <div class='col-12'>
                        <input class='fullwidth form-box-padding'
                                formControlName="area"
                                id="area"
                                minlength="1"
                                maxlength="64"
                                name="area"
                                type="text"
                                aria-label="Organization Cause Area"
                                [class.is-invalid]='registrationForm.get("area").invalid && registrationForm.get("area").touched'
                                (focusout)="validateName(registrationForm.get('area'))">
                        <div *ngIf="(registrationForm.get('area').invalid && registrationForm.get('area').touched) ||  registrationForm.get('area').dirty">
                            <small class='text-danger' *ngIf='registrationForm.get("area").errors?.required'>
                                {{ 'registration.areaRequired' | i18next }}
                            </small>
                        </div>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-12 paddingT3'>
                        <label for="referral" class="fontStyles">{{ 'registration.referral' | i18next }} <sup>&#42;</sup></label>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-12'>
                        <mat-select class='select form-box-padding' placeholder="Choose Referral" formControlName="referral"
                            [(ngModel)]="selectedReferral"
                            (ngModelChange)="referralChange($event)"
                            [class.is-invalid]='registrationForm.get("referral").invalid && registrationForm.get("referral").touched'>
                            <mat-option *ngFor="let referral of referrals" [value]="referral.sourceId">
                                <span>{{ referral.sourceName }}</span>
                            </mat-option>
                        </mat-select>
                        <div
                            *ngIf="(registrationForm.get('referral').invalid && registrationForm.get('referral').touched) || registrationForm.get('referral').dirty">
                            <small class='text-danger'
                                *ngIf='registrationForm.get("referral").errors?.required'>{{ 'registration.referral' | i18next }}
                            </small>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="showReferralName">
                    <div class='row'>
                        <div class='col-12 paddingT3'>
                            <label for="email" class="fontStyles">
                                {{ 'registration.referralName' | i18next }} <sup>&#42;</sup>
                            </label>
                        </div>
                    </div>
                    <div class='row' *ngIf='registrationForm.get("referral").value !=null && referralDescription(registrationForm.get("referral").value)'>
                        <div class='col-12'>
                            <input class='fullwidth form-box-padding'
                                    formControlName="referralName"
                                    type="text"
                                    maxlength="75"
                                    minlength="2"
                                    aria-label="Referral Name"
                                    [class.is-invalid]='registrationForm.get("referralName").invalid && registrationForm.get("referralName").touched'>
                            <div class="invalid-feedback">
                                <div *ngIf="registrationForm.get('referralName').errors?.required">
                                    {{ 'registration.referralNameRequired' | i18next }}
                                </div>
                                <div *ngIf="registrationForm.get('referralName').errors?.pattern">
                                    {{ 'registration.referralNameValidRequired' | i18next }}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class='row'>
                    <div class='col-12 paddingT3 text-middle'>
                        <mat-checkbox formControlName="terms" class="marginL1" aria-label="'registration.termsConditions' | i18next"></mat-checkbox>
                        <span class='paddingL2 text-middle fontStyles'>
                            <span (click)='openURLInNextTab("Terms&conditions")' class='link fontStyles'>{{ 'registration.termsConditions' | i18next }}</span>
                        </span>
                    </div>
                </div>
                <div class='row margin-right60px'>
                    <div class='col-12 paddinT1 text-middle paddingDiv'>
                        <mat-checkbox formControlName="privacy" class="marginL1 marginLNill" aria-label= "'registration.privacy' | i18next"></mat-checkbox>
                        <a [href]="privacyLink" target="_blank" class='paddingL2 paddingLink text-middle fontStyles' style="text-decoration: underline;">{{'registration.privacy'| i18next }}</a>
                    </div>
                </div>
                <div class='row paddingT5'>
                    <div class='col-12'>
                        <div>
                            <apk-button *ngIf="selectedType == 1 || selectedType == 4 || selectedType == 5" class='apk-button' type="primary" apk-btn-lg [disabled]="registrationForm.invalid"
                            (click)='NextStep1()'>{{ 'navigation.next' | i18next }}</apk-button>
                            <apk-button *ngIf="selectedType == 2 || selectedType == 3 || selectedType == 6" class='apk-button' type="primary" apk-btn-lg [disabled]="registrationForm.invalid"
                            (click)='NextStep1()'>Submit</apk-button> 
                        </div>
                    </div>
                    <div class='col-12 '>
                        <div class='marginT2'>
                            <span class='link fontStyles'>
                                <a class="fontStyles" [href]="Login()">{{ 'registration.faculty' | i18next }}</a>
                            </span>
                        </div>
                    </div>

                </div>
            </div>
            <div *ngIf='step2' class="col-md-9 no-padding">
                <div class='marginT4'>
                    <ng-container *ngFor='let identity of indentities'>
                        <div class='row'>
                            <div class='col-12 displaytable'>
                                <label class='fullwidth fontStyles displaytablecell'>
                                    <mat-checkbox name='{{identity.label}}' [checked]='identity.selected' aria-label= '{{identity.label}}' (change)="changeIdentity($event)"></mat-checkbox>
                                    <span class='radioBtnContent text-middle fontStyles'>{{ identity.label }}</span>
                                </label>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class='row paddingT7'>
                    <div class="col-md-12">
                        <div class='back-btn text-left'>
                            <div (click)='backToSetp1()' class='backlink'>{{ 'navigation.back' | i18next }}</div>
                        </div>
                        <div class='signUp-btn text-left'>
                            <apk-button apk-btn-lg #submitRegistration [extraInputClasses]="'submit-registration'"
                                class='apk-button submit-registration'
                                type="primary"
                                [disabled]="!identitySeleted"
                                (click)='NextStep3()'>{{ 'navigation.signUp' | i18next }}</apk-button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf='step3' class="col-md-9 no-padding">
                <div class='row BtnAlignment'>
                    <div class='col-md-6'>
                        <apk-button class='apk-button' type="primary" apk-btn-lg 
                            (click)='close()'>{{ 'navigation.close' | i18next }}</apk-button>
                    </div>
                </div>
            </div>
        </form>        
    </div>

</div>
<img src="https://ad.doubleclick.net/ddm/activity/src=2772334;type=clien872;cat=acces0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt="" />