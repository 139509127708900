<div class="container-fluid container-bg" id="main">
    <div role="region" class="user-profile-header">
        <div class='row'>
            <div class='col-4 user-header-title-bg'>
                <h1 class="user-header-title" [innerHtml]='TermsAndconditions.Pagename'>
                </h1>
            </div>
            <div class='col-8 header-img-border'>
                <img [src]="imgPath" alt="{{altTitle}}" class="user-image">
            </div>
        </div>
    </div>
    <div class="user-profile-content" role="main">
        <div class='row'>
            <div class='col-12'>
                <p class="header" [innerHtml]='TermsAndconditions.Lastupdated'></p>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.title'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b><p [innerHtml]='TermsAndconditions.Definitions'></p></b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p class='nomargin' [innerHtml]='TermsAndconditions.DefinitionsContent'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b> <p [innerHtml]='TermsAndconditions.about'></p></b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.aboutContent'></p>
            </div>
        </div><br>
        <!-- <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Definitions'></p>
                </b>
            </div>
        </div><br> -->
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Informational'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.InformationalContent'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Changes_to_availability'>
                    <p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Changes_to_availabilityContent'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Submissions_Feedback'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Submissions_FeedbackContent'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Monitoring'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.MonitoringContent'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Your_responsibilities'></p>
                </b>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Acceptable_conduct'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Acceptable_conductContent'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Prohibited_conduct'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.ProhibitedconductContent'></p>
            </div>
        </div><br>

        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Restrict_inhibit'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Restrict_inhibitContent'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Systematically_conduct'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Systematically_conductContent'></p>
            </div>
        </div><br>

        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Requirements_Websites'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Requirements_WebsitesContent'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Sign_up'></p>
                </b>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Responsibilities_Signed'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Responsibilities_SignedContent'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Privacy_Users'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Privacy_UsersContent'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Termination'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.TerminationContent'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Intellectual_property'></p>
                </b>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Our_Content'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Our_Content_Content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Your_Content'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Your_Content_Content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Ownership'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Ownership_Content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Deletion'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Deletion_Content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Compliance_Complaints'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Compliance_ComplaintsContent'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Third_Party_Content'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                    <p [innerHtml]='TermsAndconditions.Third_PartyContent_Content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Trade_marks'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Trade_marks_Content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Open_Source'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Open_Source_content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.US_Copyright_Infringement_Claims'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.US_Copyright_Infringement_Claims_content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Liability_Warranties'></p>
                </b>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Warranties'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Warranties_content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Limitation_liability'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Limitation_liabilityContent'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Indemnification'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Indemnification_content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Disclaimers_Assumptions_Risk'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Disclaimers_Assumptions_Risk_content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Other_legal_provisions'></p>
                </b>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Amendments'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Amendments_content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Privacy'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Privacy_content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Our_Remedies'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Our_Remedies_content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Enforceability'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Enforceability_content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Interpretation'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Interpretation_content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.No_implied_waiver'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.No_implied_waiver_content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Limitation_Period'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Limitation_Period_content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Notices'></p>
                </b>

            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Notices_content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Assignment'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Assignment_content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Relationship'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Relationship_content'></p>

            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Force_Majeure'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Force_Majeure_content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Entire_Agreement'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Entire_Agreement_content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.English_Language'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.English_Language_content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Severability'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Severability_content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Governing_Resolution'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Governing_Resolution_content'></p>
            </div>
        </div><br>
        <div class='row'>
            <div class='col-12'>
                <b>
                    <p [innerHtml]='TermsAndconditions.Updates'></p>
                </b>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <p [innerHtml]='TermsAndconditions.Updates_content'></p>
            </div>
        </div>
    </div>
    <div class='bottomcontainer'>
    </div>

</div>