<div id="footer" role="contentinfo" class="footer">
    <div class="justify-content-between ">
        <ul class="new-content">
            <li class="divider"><small>Access Your Potential</small></li>
            <li class="spacing"><h6>All the prep you need.</h6></li>
            <li><h6>All on your terms.</h6></li>
            <li><h6>All for free.</h6></li>
        </ul>
        <ul class="margin-content">
            <li class="pointer content spacing1 linkcontent"><a>{{"&nbsp;&nbsp;&nbsp;&nbsp;" | i18next}}</a><a href="/skills">{{'Skills' | i18next}}</a></li>
            <li class="pointer content linkcontent"><a>{{"&nbsp;&nbsp;&nbsp;&nbsp;" | i18next}}</a><a href="/mentors">{{'Mentors' | i18next}}</a></li>
            <li class="pointer content linkcontent"><a>{{"&nbsp;&nbsp;&nbsp;&nbsp;" | i18next}}</a><a href="/careers">{{'Careers' | i18next}}</a></li>
        </ul>
        <ul>
            <li class="pointer content spacing1 linkcontent"><a>{{"&nbsp;&nbsp;&nbsp;&nbsp;" | i18next}}</a><a href="/Terms&conditions"target="_blank">{{'Terms of use' | i18next}} </a></li>
            <li class="pointer content linkcontent"><a>{{"&nbsp;&nbsp;&nbsp;&nbsp;" | i18next}}</a><a href="https://s40acsr01doc01blkbsa.blob.core.windows.net/csrconnect-affiliate-storage/113/unclassified/7614486.784205.0.pdf?sv=2018-03-28&sr=b&si=csrconnect-affiliate-storage-policy&sig=Mp73HacaOYJwvOwk025mo%2BQ47rt9%2fmoJGlQg5VD%2BQuM%3D&se=9999-12-31T23%3A59%3A59Z"target="_blank">{{'Privacy' | i18next}}</a></li>
            <li class="pointer content linkcontent"><a>{{"&nbsp;&nbsp;&nbsp;&nbsp;" | i18next}}</a><a [href]="actionAboutAYP()" target="_blank">{{'About Access Your Potential' | i18next}}</a></li>
        </ul>
    </div>
        <ul style="text-align:center; letter-spacing: 0px col-12">
            <li class="content copyright">
                <img src="assets/images/PwC_Outline_Logo_White@2x.png"  (click)='navigateToHome()' class="header-logo pointer" alt="PwC (PricewaterhouseCoopers)">
                <span style="vertical-align:middle">&copy; {{currentYear}}&nbsp;PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see <a class='pointer content linkcontent text-style' href="https://www.pwc.com/structure" target="_blank">www.pwc.com/structure</a> for further details.</span>
            </li>
        </ul>
</div>