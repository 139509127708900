import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit, OnChanges {
  @Input() errorType: ErrorModalType;
  @Input() resendUrl?: string;
  @Input() customResend: boolean;

  @Output() close = new EventEmitter();
  @Output() resendConfirmation = new EventEmitter();

  showError: boolean = true;
  error: any;

  constructor(@Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService) { }
  ngOnChanges(changes: SimpleChanges) {
     this.typeoferror();
  }
  ngOnInit(): void {
    this.typeoferror();
  }
  typeoferror() {
    switch (this.errorType) {
      case ErrorModalType.REGISTERED:
        this.error = {
          text1: this.i18NextService.t("error.registered.text1"),
          text2: this.i18NextService.t("error.registered.text2", "resendUrl: this.resendUrl")
        }
        break;
      case ErrorModalType.CONFIRMED:
        this.error = {
          text1: this.i18NextService.t("error.confirmed.text1"),
          text2: this.i18NextService.t("error.confirmed.text2", "resendUrl: this.resendUrl")
        }
        break;
      case ErrorModalType.WELCOMEEMAIL:
        this.error = {
          header: this.i18NextService.t("error.welcome.header"),
          text1: this.i18NextService.t("error.welcome.text1"),
          text3: this.i18NextService.t("error.welcome.text3"),
        }
        break;
      case ErrorModalType.VALIDATEEMAIL:
        this.error = {
          header: this.i18NextService.t("error.validate.header"),
          text1: this.i18NextService.t("error.validate.text1"),
          text3: this.i18NextService.t("error.validate.text3"),
        }
        break;
      default:
        this.error = {
          header: this.i18NextService.t("error.general.header"),
          text1: this.i18NextService.t("error.general.text1")
        }
        break;
    }
  }

  resendUrlLink() {
    this.resendConfirmation.emit(this.errorType);

  }


}

export const enum ErrorModalType {
  GENERAL,
  REGISTERED,
  CONFIRMED,
  WELCOMEEMAIL,
  VALIDATEEMAIL
}