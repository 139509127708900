import { ChartTemplateService } from './chart-template.service';
import { Injectable } from '@angular/core';
import chartData from './../data/barchartdata.json' ;
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BarChartDataService {
  private barchartoptions;

  constructor(templateService: ChartTemplateService, private http: HttpClient) {
    this.barchartoptions = templateService.BarchartTemplate;
  }

  getBarchartData() {
    this.barchartoptions.series = [chartData];
    return this.barchartoptions;
  }


}
